import process from 'process';

export const ETCH_API_HOST = process?.env?.REACT_APP_ETCH_API_HOST;
export const HIGH_VOLUME_API_HOST = process?.env?.REACT_APP_HIGH_VOLUME_API_HOST;
export const SUPERDAY_API_HOST = process?.env?.REACT_APP_SERVICE_SUPERDAY_API_HOST;
export const RA_HOST = process?.env?.REACT_APP_REQUEST_AVAILABILITY_URL;
export const PORTAL_HOST = process?.env?.REACT_APP_PORTAL_URL;
export const MAX_SEARCH_TIME_IN_MILLISECONDS = 35000;
export const ENABLED_SUGGEST_TIME_SEARCH_TIME = 4000;
export const DISABLED_SUGGEST_TIME_SEARCH_TIME = 12000;
export const MAX_NUMBER_OF_SUGGESTED_TIME_CARDS = 3;
export const DD_SITE = 'datadoghq.com';
export const VERSION = process.env.REACT_APP_VERSION;
export const ENV = process?.env?.REACT_APP_ENV;
export const CLIENT_TOKEN = process?.env?.REACT_APP_DD_CLIENT_TOKEN || '';
export const CLIENT_TOKEN_RUM = process?.env?.REACT_APP_DD_CLIENT_TOKEN_RUM || '';
