import { BrowserRouter } from 'react-router-dom';
import { Provider } from './context';
import { Settings } from 'luxon';
import AppRoutes from './routes/AppRoutes';
import UserActionPublisher from './modules/user-action-tracker/UserActionPublisher';

import './App.css';
import './styles/index.scss';

const App: React.FC = () => {
    Settings.defaultLocale = navigator.language;

    return (
        <Provider>
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
            <UserActionPublisher />
        </Provider>
    );
};

export default App;
