import { AttendeeInfo, DebugInfo } from 'src/types';

export const userMapSelector = (debugInfo?: DebugInfo) => {
    const map = new Map<string, AttendeeInfo>();
    if (!debugInfo || !debugInfo.attendees) {
        return map;
    }
    return (
        debugInfo.attendees?.reduce((map, attendee) => {
            map.set(attendee.id, attendee);
            return map;
        }, map) ?? map
    );
};
