export const SUPPORTED_LOCALES = ['en', 'es', 'pt', 'de', 'nl', 'se', 'fr', 'it', 'sw', 'zh', 'ko', 'ja'];
export const DEFAULT_LOCALE = 'en';

export const mapToSupportedLocale = (language: string): string => {
    const locales = new Set(SUPPORTED_LOCALES);

    if (locales.has(language)) {
        return language;
    }

    const rootLocale = language?.split('-')[0] ?? DEFAULT_LOCALE;

    if (locales.has(rootLocale)) {
        return rootLocale;
    } else {
        return DEFAULT_LOCALE;
    }
};
