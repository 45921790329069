import {
    ApiResponse,
    ConfirmCandidateAvailabilityBody,
    ERRORS,
    ErrorResponse,
    InterviewerAlreadyBookedError,
    MeetIntro,
    MessageFromPortalDTO,
    PortalMessagesResponse,
    RescheduleCandidateAvailabilityBody,
    SchedulingLinkDataToSend,
    SchedulingLinkDataToSendResponse,
    SchedulingLinkIntroResponse,
    SearchMetadata,
    SuperdayAvailability,
    SuperdayResult,
    SuperdaySlotNotFoundError,
    UserActionState,
} from '../types';
import { DateTime } from 'luxon';
import { ETCH_API_HOST, HIGH_VOLUME_API_HOST, SUPERDAY_API_HOST } from '../utils/constants';
import {
    EmailValidationData,
    EmailValidationResponse,
    InterviewerLinkIntroData,
    InterviewerLinkIntroResponse,
    UpdateInterviewerAvailabilityData,
} from '../pages/Superday/types';
import { httpGetWithRetry, httpPost, httpPut } from './http';
import { startAvailabilitySearch, stopAvailabilitySearch } from './websocket';
import { logger } from 'src/providers/monitor.provider';

const ApiCaller = () => {
    let apiHost = ETCH_API_HOST;
    const hvHost = HIGH_VOLUME_API_HOST;
    const superdayHost = SUPERDAY_API_HOST;

    const setApiHost = (host: string) => {
        apiHost = host;
    };

    const startSearchApi = async (hash: string, debug: boolean = false): Promise<SearchMetadata | undefined> => {
        const browserTimezone = DateTime.local().zone.name;
        const url = `${apiHost}/v2/meet/availability/search/${hash}?browserTimezone=${browserTimezone}`;
        const params: Record<string, string> = {};
        let includeCredentials = false;
        if (debug) {
            params['debug'] = 'true';
            includeCredentials = true;
        }
        return httpGetWithRetry<ApiResponse<{ searchMetadata: SearchMetadata }> | ErrorResponse>(
            url,
            params,
            undefined,
            undefined,
            undefined,
            includeCredentials,
        ).then((response) => {
            if (response?.status === 'error') {
                throw new Error((response as ErrorResponse).gtErrorName);
            }
            return (response as ApiResponse<{ searchMetadata: SearchMetadata }>).result.searchMetadata;
        });
    };

    const searchSuperdayApi = async (hash: string, isReschedule?: boolean): Promise<SuperdayResult[] | undefined> => {
        return httpGetWithRetry<ApiResponse<{ availability: SuperdayResult[] }> | ErrorResponse>(
            `${superdayHost}/interviews/${hash}/request-availability${isReschedule ? '?reschedule=true' : ''}`,
        ).then((response) => {
            if (response?.status === 'error') {
                throw new Error((response as ErrorResponse).gtErrorName);
            }
            return (response as ApiResponse<{ availability: SuperdayResult[] }>).result.availability;
        });
    };

    const confirmSuperdayAvailabilityApi = async (
        hash: string,
        availabilities: SuperdayAvailability[],
        csrfToken: string,
        reschedule?: boolean,
    ): Promise<MeetIntro> => {
        return httpPost<ApiResponse<{ confirmed: MeetIntro }>>(
            `${superdayHost}/interviews/${hash}/confirm-availability`,
            JSON.stringify({ availabilities, reschedule, _csrf: csrfToken }),
        )
            .then(handleConfirmSuperdayAvailability)
            .catch((error) => {
                if (error.gtErrorCode === ERRORS.SUPERDAY_SLOT_NOT_FOUND) {
                    throw new SuperdaySlotNotFoundError((error as ErrorResponse).message);
                }
                throw new Error('Error confirming superday availability');
            });
    };

    const getMeetIntroApi = async (hash: string): Promise<MeetIntro | undefined> => {
        return httpGetWithRetry<ApiResponse<MeetIntro>>(`${apiHost}/v2/meet/get_intro/${hash}`)
            .then((response) => response.result)
            .catch((error) => ({ ...error, hasError: true }));
    };

    const getCandidatePortalApi = async (
        guestId: string,
        jobId: string,
        applicationId?: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any | undefined> => {
        let url = `${apiHost}/v2/meet/candidate-portal/${guestId}/${jobId}`;
        if (applicationId) {
            url += `/${applicationId}`;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return httpGetWithRetry<ApiResponse<any>>(url)
            .then((response) => ({ ...response.result, hasError: false }))
            .catch((error) => ({ ...error, hasError: true }));
    };

    const getSchedulingLinkIntroApi = async (link: string): Promise<SchedulingLinkIntroResponse | undefined> => {
        return httpGetWithRetry<ApiResponse<SchedulingLinkIntroResponse>>(
            `${hvHost}/scheduling-link/intro/${link}`,
        ).then((response) => response.result);
    };

    const getCandidatePortalMessagesApi = async (
        etchOrganizationId: string,
        etchGuestId: string,
    ): Promise<PortalMessagesResponse | undefined> => {
        return httpGetWithRetry<ApiResponse<PortalMessagesResponse>>(
            `${hvHost}/message/messages-to-portal?oid=${etchOrganizationId}&gid=${etchGuestId}`,
        ).then((response) => response.result);
    };

    const postPortalMessageApi = async (
        portalMessage: MessageFromPortalDTO,
    ): Promise<{ message: string } | undefined> => {
        return httpPost<ApiResponse<{ message: string }>>(
            `${hvHost}/message/messaging-from-portal`,
            JSON.stringify({ ...portalMessage }),
        ).then((response) => response.result);
    };

    const getCSRFTokenFromServerApi = async (): Promise<{ csrfToken: string }> => {
        return httpGetWithRetry<{ csrfToken: string }>(`${apiHost}/refresh`)
            .then((response) => response)
            .catch((error) => {
                if (error?.message) {
                    logger.info('getCSRFTokenFromServerApi [error.message]', error?.message);
                }
                logger.info('getCSRFTokenFromServerApi [error]', error);

                throw new Error(`Error getting the refresh token: ${JSON.stringify(error)}`);
            });
    };

    const handleConfirmCandidateAvailability = (response: ApiResponse<MeetIntro>) => {
        if (response.gtErrorCode === ERRORS.INTERVIEWER_ALREADY_SCHEDULED) {
            throw new InterviewerAlreadyBookedError((response as ErrorResponse).message);
        }
        return response.result;
    };

    const handleConfirmSuperdayAvailability = (response: ApiResponse<{ confirmed: MeetIntro }>) => {
        if (response.gtErrorCode === ERRORS.INTERVIEWER_ALREADY_SCHEDULED) {
            throw new InterviewerAlreadyBookedError((response as ErrorResponse).message);
        }
        return response.result.confirmed;
    };

    const postConfirmCandidateAvailabilityApi = async (
        hash: string,
        confirmCandidateAvailabilityBody: ConfirmCandidateAvailabilityBody,
        csrfToken: string,
    ): Promise<MeetIntro | undefined> => {
        return httpPost<ApiResponse<MeetIntro>>(
            `${apiHost}/v2/meet/confirm_candidate_availability/${hash}`,
            JSON.stringify({ ...confirmCandidateAvailabilityBody, _csrf: csrfToken }),
        ).then(handleConfirmCandidateAvailability);
    };

    const postCandidateRescheduleApi = async (
        hash: string,
        rescheduleReason: RescheduleCandidateAvailabilityBody,
        csrfToken: string,
    ): Promise<MeetIntro | undefined> => {
        return httpPost<ApiResponse<MeetIntro>>(
            `${apiHost}/v2/meet/reschedule-request-availability/${hash}`,
            JSON.stringify({ ...rescheduleReason, _csrf: csrfToken }),
        ).then(handleConfirmCandidateAvailability);
    };

    const sendSchedulingLinkDataApi = async (
        data: SchedulingLinkDataToSend,
    ): Promise<SchedulingLinkDataToSendResponse | undefined> => {
        return httpPost<ApiResponse<SchedulingLinkDataToSendResponse>>(
            `${hvHost}/scheduling-link/schedule-interview`,
            JSON.stringify(data),
        ).then((response) => response.result);
    };

    const getInterviewerLinkIntroApi = async (
        data: InterviewerLinkIntroData,
    ): Promise<InterviewerLinkIntroResponse | undefined> => {
        return httpGetWithRetry<ApiResponse<InterviewerLinkIntroResponse>>(
            `${superdayHost}/superday/interviewer-link/intro?organizationSlug=${data.organizationSlug}&customUrl=${data.customUrl}`,
        ).then((response) => response.result);
    };

    const getLinkAvailabilityApi = async (data: EmailValidationData): Promise<EmailValidationResponse | undefined> => {
        const id = data.id;
        return httpGetWithRetry<ApiResponse<EmailValidationResponse>>(
            `${superdayHost}/superday/interviewer-link/${id}/availability?id=${id}&interviewerEmail=${data.email}`,
        ).then((response) => response.result);
    };

    const updateInterviewerAvailability = async (
        data: UpdateInterviewerAvailabilityData,
    ): Promise<EmailValidationResponse | undefined> => {
        const id = data.interviewerLinkId;
        return httpPut<ApiResponse<EmailValidationResponse>>(
            `${superdayHost}/superday/interviewer-link/${id}/availability`,
            JSON.stringify(data),
        ).then((response) => response.result);
    };

    const postUserActionTrackingApi = async (csrfToken: string, hash: string, userActionState: UserActionState) => {
        return httpPost<ApiResponse<UserActionState & Record<string, unknown>>>(
            `${apiHost}/v2/meet/track-request-availability/${hash}`,
            JSON.stringify({
                ...userActionState,
                hash,
            }),
        ).then((response) => {
            if (response?.status === 'error') {
                throw new Error((response as ErrorResponse).gtErrorName);
            }
            return response.result;
        });
    };

    return {
        startSearchApi,
        searchSuperdayApi,
        getMeetIntroApi,
        getCSRFTokenFromServerApi,
        postConfirmCandidateAvailabilityApi,
        confirmSuperdayAvailabilityApi,
        postCandidateRescheduleApi,
        getSchedulingLinkIntroApi,
        sendSchedulingLinkDataApi,
        setApiHost,
        postUserActionTrackingApi,
        getCandidatePortalApi,
        getInterviewerLinkIntroApi,
        getLinkAvailabilityApi,
        updateInterviewerAvailability,
        getCandidatePortalMessagesApi,
        postPortalMessageApi,
    };
};

const {
    startSearchApi,
    searchSuperdayApi,
    getMeetIntroApi,
    getCSRFTokenFromServerApi,
    postConfirmCandidateAvailabilityApi,
    confirmSuperdayAvailabilityApi,
    postCandidateRescheduleApi,
    getSchedulingLinkIntroApi,
    sendSchedulingLinkDataApi,
    setApiHost,
    postUserActionTrackingApi,
    getCandidatePortalApi,
    getInterviewerLinkIntroApi,
    getLinkAvailabilityApi,
    updateInterviewerAvailability,
    getCandidatePortalMessagesApi,
    postPortalMessageApi,
} = ApiCaller();

export {
    startSearchApi,
    searchSuperdayApi,
    getMeetIntroApi,
    getCSRFTokenFromServerApi,
    postConfirmCandidateAvailabilityApi,
    confirmSuperdayAvailabilityApi,
    postCandidateRescheduleApi,
    startAvailabilitySearch,
    stopAvailabilitySearch,
    getSchedulingLinkIntroApi,
    sendSchedulingLinkDataApi,
    setApiHost,
    postUserActionTrackingApi,
    getCandidatePortalApi,
    getInterviewerLinkIntroApi,
    getLinkAvailabilityApi,
    updateInterviewerAvailability,
    getCandidatePortalMessagesApi,
    postPortalMessageApi,
};
