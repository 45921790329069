import { LocalStorageProperty, UserAgent } from '../types';

export type QueryParams = Record<string, string | number | boolean>;

const getUserAgent = () => {
    return localStorage.getItem(LocalStorageProperty.UserAgent) ?? UserAgent.REQUEST_AVAILABILITY;
};

export async function httpGet<T>(url: string, params?: QueryParams, headers?: HeadersInit, includeCredentials = false): Promise<T> {
    const newUrl = writeGetParams(url, params);

    const response = await fetch(newUrl, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            UserAgent: getUserAgent(), // set user-agent header using fetch doesn't work

            ...headers,
        },
        credentials: includeCredentials ? 'include' : undefined,
    });
    if (!response.ok) {
        throw await response.json();
    }
    return response.json();
}

export async function httpPost<T>(url: string, body?: BodyInit, headers?: HeadersInit): Promise<T> {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            UserAgent: getUserAgent(),
            ...headers,
        },
        body,
    });
    if (!response.ok) {
        throw await response.json();
    }
    return response.json();
}

export async function httpPut<T>(url: string, body?: BodyInit, headers?: HeadersInit): Promise<T> {
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            UserAgent: getUserAgent(),
            ...headers,
        },
        body,
    });
    if (!response.ok) {
        throw await response.json();
    }
    return response.json();
}

const writeGetParams = (url: string, params?: QueryParams): string => {
    if (!params) {
        return url;
    }
    let query = Object.keys(params).reduce((url: string, paramName: string) => {
        if (!params[paramName]) {
            return url;
        }
        return `${url}&${paramName}=${params[paramName]}`;
    }, '');
    query = query === '' ? query : `?${query}`;
    return `${url}${query}`;
};

const retry = <T>(fn: () => Promise<T>, retries = 3, delay = 1000): Promise<T> => {
    return new Promise((resolve, reject) => {
        const attempt = (n: number) => {
            fn()
                .then(resolve)
                .catch((err) => {
                    if (n === 0 || err.code >= 500) {
                        reject(err);
                    } else {
                        setTimeout(() => {
                            attempt(n - 1);
                        }, delay);
                    }
                });
        };

        attempt(retries);
    });
};

export async function httpGetWithRetry<T>(
    url: string,
    params?: QueryParams,
    headers?: HeadersInit,
    retries = 3,
    delay = 1000,
    includeCredentials = false,
): Promise<T> {
    return retry(() => httpGet<T>(url, params, headers, includeCredentials), retries, delay);
}
