import { AppContext } from '../../context';
import { postUserActionTrackingApi } from '../../api';
import { useContext, useEffect } from 'react';

const UserActionPublisher: React.FC = () => {
    const { userActionTracker, token } = useContext(AppContext);

    useEffect(() => {
        if (userActionTracker?.hash) {
            // Fire and forget the user tracking api call
            postUserActionTrackingApi(token ?? '', userActionTracker?.hash, userActionTracker);
        }
    }, [userActionTracker, token]);

    return null;
};

export default UserActionPublisher;
