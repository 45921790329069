export enum ERRORS {
    INTERVIEWER_ALREADY_SCHEDULED = 50079,
    SCHEDULING_LINK_NOT_FOUND = 10001,
    SUPERDAY_SLOT_NOT_FOUND = 20013,
}

export interface ErrorResponse {
    code?: number | null;
    errCode?: number | null;
    gtErrorCode?: number | null;
    gtErrorName?: string;
    message?: string;
    status?: string;
}

export class APIError {
    errCode?: number;
    message?: string;

    constructor(errCode?: number, message?: string) {
        this.errCode = errCode;
        this.message = message;
    }
}

export class InterviewerAlreadyBookedError extends APIError {
    constructor(message?: string) {
        super(ERRORS.INTERVIEWER_ALREADY_SCHEDULED, message);
    }
}

export class SuperdaySlotNotFoundError extends APIError {
    constructor(message?: string) {
        super(ERRORS.SUPERDAY_SLOT_NOT_FOUND, message);
    }
}
