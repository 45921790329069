import { CLIENT_TOKEN, CLIENT_TOKEN_RUM, DD_SITE, ENV, PORTAL_HOST, RA_HOST, VERSION } from '../utils/constants';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export interface User {
    id?: string | undefined;
    email?: string | undefined;
    name?: string | undefined;
    [key: string]: unknown;
}

const env = ENV || 'stage';
const portalUrl = PORTAL_HOST || '';
const raUrl = RA_HOST || '';
const service = 'request-availability';
if (!location.host.includes('localhost')) {
    datadogRum.init({
        applicationId: '36405913-e475-4645-8e86-57085d0a8c89',
        clientToken: CLIENT_TOKEN_RUM,
        site: DD_SITE,
        service: service,
        version: VERSION,
        allowedTracingUrls: [
            {
                propagatorTypes: ['datadog'],
                match: portalUrl,
            },
            {
                propagatorTypes: ['datadog'],
                match: raUrl,
            },
        ],
        trackResources: true,
        trackLongTasks: true,
        sessionSampleRate: 15,
        sessionReplaySampleRate: 10,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        env,
    });

    datadogLogs.init({
        clientToken: CLIENT_TOKEN,
        site: DD_SITE,
        service: service,
        version: VERSION,
        forwardConsoleLogs: ['warn', 'error'],
        forwardErrorsToLogs: true,
        forwardReports: ['deprecation', 'csp_violation'],
        sessionSampleRate: 100,
        env,
    });

    // logs context
    datadogLogs.setGlobalContextProperty('service', service);
    datadogLogs.setGlobalContextProperty('env', ENV);
    datadogLogs.setGlobalContextProperty('version', VERSION);

    // rum context
    datadogRum.setGlobalContextProperty('service', service);
    datadogRum.setGlobalContextProperty('env', ENV);
    datadogRum.setGlobalContextProperty('version', VERSION);
}

export const setUser = (user: User): void => {
    datadogLogs.setUser(user);
    datadogRum.setUser(user);
};

export const setInfo = (domain: string, orgId: string): void => {
    datadogLogs.setGlobalContextProperty('domain', domain);
    datadogRum.setGlobalContextProperty('domain', domain);
    datadogLogs.setGlobalContextProperty('orgId', orgId);
    datadogRum.setGlobalContextProperty('orgId', orgId);
};

export const logger = datadogLogs.logger;
