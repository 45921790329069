import { AppAction } from './actions';
import { AppActions, AppState } from '.';
import {
    AvailableInterviewOptions,
    DebugState,
    Duration,
    EventResult,
    FilterOptions,
    HashedOption,
    Interview,
    InterviewStatus,
    MeetIntro,
    MultiDayMap,
    MultiDayOption,
    MultiDayOptionGroup,
    Resource,
    Result,
    SchedulingLinkDataToSendResponse,
    SchedulingLinkIntroResponse,
    Score,
    SearchMetadata,
    SetUserActionTrackerActionInput,
    SingleDayMap,
    SingleDayOption,
    SingleDayOptionGroup,
    SuperdayResult,
} from '../../types';
import { DateTime } from 'luxon';
import { InterviewerAvailabilityData, LinkAvailabilityData } from '../../pages/Superday/types';
import { computeNextUserTrackerState } from '../../modules/user-action-tracker/compute-state.service';
import { getDateShort, getTimeCategory } from '../../utils/date-utils';
import { raConstraintsFilter } from '../../utils/ra-constraints';
import { userMapSelector } from '../selectors';

export const receiveInterview = (state: AppState, action: AppAction<Interview>): AppState => {
    if (action.type === AppActions.RECEIVE_INTERVIEW) {
        return {
            ...state,
            interview: action.payload,
        };
    }
    return state;
};

export const receiveSearchMetadata = (state: AppState, action: AppAction<Resource<SearchMetadata>>): AppState => {
    if (action.type === AppActions.RECEIVE_SEARCH_METADATA) {
        const userMap = userMapSelector(action.payload?.data?.debugInfo);
        const searchMetadata = action.payload;
        if (searchMetadata?.data === undefined) {
            return {
                ...state,
                searchMetadata: action.payload,
            };
        }
        const { debugInfo, ...rest } = searchMetadata.data;
        return {
            ...state,
            searchMetadata: {
                ...searchMetadata,
                data: {
                    ...rest,
                    error: searchMetadata?.data?.error,
                },
            },
            debug: {
                ...state.debug,
                ...debugInfo,
                userMap,
            },
        };
    }
    return state;
};

export const receiveMeetIntro = (state: AppState, action: AppAction<Resource<MeetIntro>>): AppState => {
    if (action.type === AppActions.RECEIVE_MEET_INTRO) {
        const currentMeet = action.payload;
        if (currentMeet?.data?.interview) {
            if (
                (state?.meetIntro?.data?.interview?.status === InterviewStatus.RESCHEDULE ||
                    state.rescheduleReason?.reasonCode) &&
                currentMeet.data.interview.status !== InterviewStatus.PENDING_REVIEW
            ) {
                currentMeet.data.interview.status = InterviewStatus.RESCHEDULE;
            }
        }
        return {
            ...state,
            meetIntro: currentMeet,
        };
    }
    return state;
};

export const receiveSchedulingLinkIntro = (
    state: AppState,
    action: AppAction<Resource<SchedulingLinkIntroResponse>>,
): AppState => {
    if (action.type === AppActions.RECEIVE_SCHEDULING_LINK_INTRO) {
        const schedulingLinkIntro = action.payload;
        return {
            ...state,
            schedulingLinkIntro: {
                data: schedulingLinkIntro?.data,
                isLoading: schedulingLinkIntro?.isLoading ?? false,
                error: schedulingLinkIntro?.error,
            },
        };
    }
    return state;
};

export const sendSchedulingLinkData = (
    state: AppState,
    action: AppAction<Resource<SchedulingLinkDataToSendResponse>>,
): AppState => {
    if (action.type === AppActions.SEND_SCHEDULING_LINK_DATA) {
        const dataSentResponse = action.payload;
        return {
            ...state,
            schedulingLinkDataSent: {
                data: dataSentResponse?.data,
                isLoading: dataSentResponse?.isLoading ?? false,
                error: dataSentResponse?.error,
            },
        };
    }
    return state;
};

export const setLinkAvailabilityData = (
    state: AppState,
    action: AppAction<Resource<LinkAvailabilityData>>,
): AppState => {
    if (action.type === AppActions.SET_LINK_AVAILABILITY_DATA) {
        const dataSentResponse = action.payload;
        return {
            ...state,
            linkAvailabilityData: {
                data: dataSentResponse?.data,
                isLoading: dataSentResponse?.isLoading ?? false,
                error: dataSentResponse?.error,
            },
        };
    }
    return state;
};

export const setInterviewerAvailabilityData = (
    state: AppState,
    action: AppAction<Resource<InterviewerAvailabilityData>>,
): AppState => {
    if (action.type === AppActions.SET_INTERVIEWER_AVAILABILITY_DATA) {
        const dataSentResponse = action.payload;
        return {
            ...state,
            interviewerAvailabilityData: {
                data: dataSentResponse?.data,
                isLoading: dataSentResponse?.isLoading ?? false,
                error: dataSentResponse?.error,
            },
        };
    }
    return state;
};

export const receiveApiError = (state: AppState, action: AppAction<Resource<MeetIntro>>): AppState => {
    if (action.type === AppActions.ADD_API_ERROR) {
        const data = state.meetIntro?.data ?? action.payload?.data;
        const meetIntroWithError = {
            data: data ? { ...data } : undefined,
            error: action.payload?.error,
            isLoading: action.payload?.isLoading ?? false,
        };

        return {
            ...state,
            meetIntro: meetIntroWithError,
        };
    }
    return state;
};

export const addDays = (state: AppState, action: AppAction<string[]>): AppState => {
    if (action.type === AppActions.ADD_DAYS) {
        return {
            ...state,
            selectedDays: action.payload,
        };
    }
    return state;
};
export const addTimeSlot = (state: AppState, action: AppAction<string>): AppState => {
    if (action.type === AppActions.ADD_TIME_SLOT) {
        let timeSlots: (string | undefined)[] = state.selectedTimeSlots ? state.selectedTimeSlots : [];
        if (action?.payload && state?.selectedTimeSlots?.includes(action?.payload)) {
            timeSlots = timeSlots.filter((timeSlot) => timeSlot !== action.payload);
        } else {
            timeSlots.push(action.payload);
        }
        const displayResults = runDebugFilters(state.debug, timeSlots);
        return {
            ...state,
            selectedTimeSlots: timeSlots,
            debug: {
                ...state.debug,
                displayResults,
            },
        };
    }
    return state;
};

export const addTimeSlots = (state: AppState, action: AppAction<string[]>): AppState => {
    if (action.type === AppActions.ADD_TIME_SLOTS) {
        const timeSlots: (string | undefined)[] = state.selectedTimeSlots ? state.selectedTimeSlots : [];

        const arr = [...(timeSlots?.length ? timeSlots : []), ...(action?.payload?.length ? action.payload : [])];

        const uniqueTimeSlots = [...new Set(arr)];
        const displayResults = runDebugFilters(state.debug, uniqueTimeSlots);
        return {
            ...state,
            selectedTimeSlots: uniqueTimeSlots,
            debug: {
                ...state.debug,
                displayResults,
            },
        };
    }
    return state;
};

export const setDebugMode = (state: AppState, action: AppAction<boolean>): AppState => {
    if (action.type === AppActions.SET_DEBUG_MODE) {
        return {
            ...state,
            debug: {
                ...state.debug,
                debugMode: action.payload ?? false,
            },
        };
    }
    return state;
};

const runDebugFilters = (state: DebugState, selectedHashes?: (string | undefined)[]): Result[] => {
    const { filterOptions, userMap, debugResults } = state;
    const hashes = new Set((selectedHashes ?? []).filter((hash) => Boolean(hash)) as string[]);
    let options = debugResults;
    if (filterOptions.term) {
        const term = filterOptions.term.toLowerCase();
        options = debugResults.filter((result) =>
            result.events.some((event) =>
                event.attendees.some((id) => {
                    return userMap.get(id)?.name.toLowerCase().includes(term);
                }),
            ),
        );
    }
    if (filterOptions.filterBySelectedSlots && (selectedHashes?.length ?? 0) > 0) {
        options = options?.filter((result) => hashes.has(result.hash));
    }
    return (options ?? []).sort(sortByStartTime);
};

const sortByStartTime = (a: Result, b: Result) => {
    return DateTime.fromISO(a.startTime).diff(DateTime.fromISO(b.startTime)).milliseconds;
};

export const setFilterOptions = (state: AppState, action: AppAction<FilterOptions>): AppState => {
    if (action.type === AppActions.SET_FILTER_OPTIONS && action.payload) {
        const filterOptions = action.payload;
        const displayResults = runDebugFilters({ ...state.debug, filterOptions }, state.selectedTimeSlots);
        return {
            ...state,
            debug: {
                ...state.debug,
                filterOptions,
                displayResults,
            },
        };
    }
    return state;
};

export const updateSelectedLanguage = (state: AppState, action: AppAction<string>): AppState => {
    if (action.type === AppActions.UPDATE_SELECTED_LANGUAGE) {
        return {
            ...state,
            selectedLanguage: action.payload ? action.payload : 'en',
        };
    }
    return state;
};

export const receiveAlgoResults = (state: AppState, action: AppAction<Result[]>): AppState => {
    if (action.type === AppActions.RECEIVE_ALGO_RESULTS) {
        let debug = state.debug;
        if (state.debug?.debugMode) {
            const debugResults = [...(debug?.debugResults ?? []), ...(action.payload ?? [])];
            debug = {
                ...debug,
                debugResults,
                displayResults: runDebugFilters({ ...state.debug, debugResults }, state.selectedTimeSlots),
            };
        }

        return {
            ...state,
            results: [...(action.payload?.filter((result) => raConstraintsFilter(result, state?.meetIntro?.data?.interview?.settings?.needsReview)) ?? []), ...state.results],
            debug,
            lastResultReceivedTime: DateTime.now(),
        };
    }
    return state;
};

export const receiveSuperdayResults = (state: AppState, action: AppAction<SuperdayResult[]>): AppState => {
    if (action.type === AppActions.RECEIVE_SUPERDAY_RESULTS) {
        const superdayResults = action.payload ?? [];
        const availableInterviewOptions = getAvailableSuperdayInterviewOptions(
            state.availableInterviewOptions,
            superdayResults,
            state?.meetIntro?.data?.guest?.settings?.timeZone,
        );
        return {
            ...state,
            availableInterviewOptions,
            superdayResults,
        };
    }
    return state;
};

export const receiveAvailableInterviewOptions = (state: AppState, action: AppAction<Result[]>): AppState => {
    if (action.type === AppActions.RECEIVE_ALGO_RESULTS) {
        const results = action.payload ?? [];
        const filteredResults = action.payload?.filter((result) => raConstraintsFilter(result, state?.meetIntro?.data?.interview?.settings?.needsReview)) ?? [];

        const availableInterviewOptions = getAvailableInterviewOptions(
            state.availableInterviewOptions,
            filteredResults,
            state?.meetIntro?.data?.guest?.settings?.timeZone,
        );

        const { total = 0, available = 0 } = state.interviewOptionsCounts;
        return {
            ...state,
            availableInterviewOptions,
            interviewOptionsCounts: {
                ...state.interviewOptionsCounts,
                total: total + results.length,
                available: available + filteredResults.length,
            },
        };
    }
    return state;
};

export const getCSRFToken = (state: AppState, action: AppAction<{ csrfToken: string }>): AppState => {
    if (action.type === AppActions.GET_CSRF_TOKEN) {
        return {
            ...state,
            token: action?.payload?.csrfToken ? action.payload.csrfToken : undefined,
        };
    }
    return state;
};

const getDurationFromTimes = (
    startTimeISO?: string,
    endTimeISO?: string,
    timezone: string = DateTime.local().zoneName,
): Duration | undefined => {
    if (!startTimeISO || !endTimeISO) {
        return undefined;
    }

    const startTime = DateTime.fromISO(startTimeISO, { zone: timezone });
    const endTime = DateTime.fromISO(endTimeISO, { zone: timezone });
    const diff = endTime.diff(startTime, ['hours', 'minutes']).toObject();
    let durationTime = '';

    if (diff.hours) {
        durationTime = `${diff.hours}h`;
    }

    if (diff.minutes) {
        if (durationTime) {
            durationTime += ' ';
        }
        durationTime += `${diff.minutes}m`;
    }

    return {
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
        durationTime,
    };
};

const checkIfResultHashExists = (hash: string, options: HashedOption[]) => {
    return options.find((option) => option.hash === hash);
};

const scoreComparator = (scoreA: Score, scoreB: Score): number => {
    const SolutionScoreLength = 4;
    for (let i = 0; i < SolutionScoreLength; ++i) {
        const scoreDiff = (scoreB[i] ?? 0) - (scoreA[i] ?? 0);
        if (scoreDiff !== 0) return scoreDiff;
    }
    return 0;
};

const sortByBestFit = (r1: Result, r2: Result) => {
    return scoreComparator(r1.score, r2.score);
};

const getAvailableInterviewOptions = (
    availableInterviewOptions: AvailableInterviewOptions,
    results: Result[],
    timezone: string = DateTime.local().zoneName,
): AvailableInterviewOptions => {
    const { singleDay = new Map<string, SingleDayOption[]>(), multiDay = new Map<number, MultiDayOption[]>() } =
        availableInterviewOptions;

    results.sort(sortByBestFit);

    results.forEach((result) => {
        const days = result.events.reduce((eventDates: Set<string>, event: EventResult) => {
            const key = getDateShort(DateTime.fromISO(event.startTime, { zone: timezone }), 'en');
            return eventDates.add(key);
        }, new Set());

        const isSingleDay = days.size === 1;

        const sortedEvents = result.events.sort((a, b) => a.startTime.localeCompare(b.startTime));
        if (isSingleDay) {
            const [firstDay = ''] = days;
            const singleDayOption = singleDay.get(firstDay) || [];
            if (!checkIfResultHashExists(result.hash, singleDayOption)) {
                const singleDayStartTime = sortedEvents[0]?.startTime;
                const singleDayEndTime = sortedEvents[sortedEvents.length - 1]?.endTime;
                const duration = getDurationFromTimes(singleDayStartTime, singleDayEndTime, timezone);

                if (duration) {
                    const startTimeObj: DateTime = DateTime.fromISO(duration.startTime, { zone: timezone });
                    singleDayOption.push({
                        duration,
                        hash: result.hash,
                        timeCategory: getTimeCategory(startTimeObj),
                    });
                }
            }
            singleDay.set(firstDay, singleDayOption);
        } else {
            const multiDayOption = multiDay.get(days.size) || [];
            if (!checkIfResultHashExists(result.hash, multiDayOption)) {
                const eventsByDay = sortedEvents.reduce((eventsByDay, eventResult) => {
                    const eventDay = DateTime.fromISO(eventResult.startTime, { setZone: true }).toLocaleString(
                        DateTime.DATE_SHORT,
                    );

                    if (!eventsByDay.has(eventDay)) {
                        eventsByDay.set(eventDay, []);
                    }

                    eventsByDay.get(eventDay)?.push(eventResult);

                    return eventsByDay;
                }, new Map<string, EventResult[]>());

                const durations: Duration[] = Array.from(eventsByDay.values())
                    .map((eventResults) =>
                        getDurationFromTimes(
                            eventResults[0]?.startTime,
                            eventResults[eventResults.length - 1]?.endTime,
                            timezone,
                        ),
                    )
                    .filter((duration) => Boolean(duration)) as Duration[];

                multiDayOption.push({
                    duration: durations,
                    hash: result.hash,
                });
            }
            multiDay.set(days.size, multiDayOption);
        }
    });
    const interviewOptions = {
        singleDay: removeSingleDayDuplicates(singleDay),
        multiDay: removeMultiDayDuplicates(multiDay),
        timezone,
    };
    sortSingleDayOptions(interviewOptions.singleDay);
    sortMultiDayOptions(interviewOptions.multiDay);
    return interviewOptions;
};

const getAvailableSuperdayInterviewOptions = (
    availableInterviewOptions: AvailableInterviewOptions,
    results: SuperdayResult[],
    timezone: string = DateTime.local().zoneName || '',
): AvailableInterviewOptions => {
    const { singleDay = new Map<string, SingleDayOption[]>(), multiDay = new Map<number, MultiDayOption[]>() } =
        availableInterviewOptions;

    results.forEach((result) => {
        const options: SingleDayOption[] = [];
        if (result.options.length) {
            result.options.forEach((option) => {
                const {
                    startTime,
                    endTime,
                    iteration,
                    interviewBlockId,
                    interviewBlockStartTime,
                    interviewTimeBlockIndex,
                } = option;
                const duration = getDurationFromTimes(startTime, endTime, timezone);
                if (duration) {
                    const startTimeObj: DateTime = DateTime.fromISO(duration.startTime, { zone: timezone });
                    options.push({
                        duration,
                        timeCategory: getTimeCategory(startTimeObj),
                        hash: `${startTime}-${iteration}-${interviewBlockId}`,
                        iteration,
                        interviewBlockId,
                        interviewBlockStartTime,
                        interviewTimeBlockIndex,
                    });
                }
            });

            if (options.length) {
                const startTime = options[0]?.duration.startTime;
                if (startTime) {
                    const key = getDateShort(DateTime.fromISO(startTime, { zone: timezone }), 'en');
                    singleDay.set(key, options);
                }
            }
        }
    });
    const interviewOptions = {
        singleDay: singleDay,
        multiDay: multiDay,
        timezone,
    };
    sortSingleDayOptions(interviewOptions.singleDay);
    return interviewOptions;
};

const sortSingleDayOptions = (singleDay: SingleDayOptionGroup) => {
    const days = singleDay.keys();
    for (const day of days) {
        singleDay.get(day)?.sort((a, b) => {
            const startTimeA = DateTime.fromISO(a.duration.startTime);
            const startTimeB = DateTime.fromISO(b.duration.startTime);
            return startTimeA.diff(startTimeB, ['minutes']).minutes;
        });
    }
};

const sortMultiDayOptions = (multiDay: MultiDayOptionGroup) => {
    const days = multiDay.keys();
    for (const day of days) {
        multiDay.get(day)?.sort((a, b) => {
            if (a.duration[0]?.startTime && b.duration[0]?.startTime) {
                const startTimeA = DateTime.fromISO(a.duration[0]?.startTime);
                const startTimeB = DateTime.fromISO(b.duration[0]?.startTime);
                return startTimeA.diff(startTimeB, ['minutes']).minutes;
            }
            return 0;
        });
    }
};

const removeSingleDayDuplicates = (singleDay: SingleDayMap): SingleDayMap => {
    const filterDuplicates = ({ duration: { startTime, endTime } }: SingleDayOption) => {
        const singleDayKey = `${startTime}-${endTime}`;
        if (singleDaySet.has(singleDayKey)) {
            return false;
        }
        singleDaySet.add(singleDayKey);
        return true;
    };
    const singleDaySet = new Set<string>();
    const days = singleDay.keys();
    for (const day of days) {
        const singleDayOptions = singleDay.get(day);
        singleDay.set(day, singleDayOptions?.filter(filterDuplicates) || []);
    }
    return singleDay;
};

const removeMultiDayDuplicates = (multiDayMap: MultiDayMap): MultiDayMap => {
    const filterDuplicates = (option: MultiDayOption) => {
        const { duration } = option;
        const key = duration.reduce((key, { startTime, endTime }) => {
            return `${key}-${startTime}-${endTime}`;
        }, '');
        if (multiDaySet.has(key)) {
            return false;
        }
        multiDaySet.add(key);
        return true;
    };
    const multiDaySet = new Set<string>();
    const days = multiDayMap.keys();
    for (const nDays of days) {
        const multiDayOptions = multiDayMap.get(nDays);
        multiDayMap.set(nDays, multiDayOptions?.filter(filterDuplicates) ?? []);
    }
    return multiDayMap;
};

export const addTimezone = (state: AppState, action: AppAction<string>): AppState => {
    if (action.type === AppActions.ADD_TIMEZONE) {
        const timezone = action.payload ?? '';
        const meetIntro = state.meetIntro;
        if (meetIntro?.data?.guest?.settings) {
            meetIntro.data.guest.settings.timeZone = timezone;
        }
        const availableInterviewOptions = getAvailableInterviewOptions(
            {
                singleDay: new Map<string, SingleDayOption[]>(),
                multiDay: new Map<number, MultiDayOption[]>(),
                timezone: timezone,
            },
            state.results ?? [],
            timezone,
        );
        return {
            ...state,
            availableInterviewOptions: { ...availableInterviewOptions, timezone },
        };
    }
    return state;
};

export const addSuperdayTimezone = (state: AppState, action: AppAction<string>): AppState => {
    if (action.type === AppActions.ADD_SUPERDAY_TIMEZONE) {
        const timezone = action.payload ?? '';
        const meetIntro = state.meetIntro;
        if (meetIntro?.data?.guest?.settings) {
            meetIntro.data.guest.settings.timeZone = timezone;
        }
        const availableInterviewOptions = getAvailableSuperdayInterviewOptions(
            {
                singleDay: new Map<string, SingleDayOption[]>(),
                multiDay: new Map<number, MultiDayOption[]>(),
                timezone: timezone,
            },
            state.superdayResults ?? [],
            timezone,
        );
        return {
            ...state,
            availableInterviewOptions: { ...availableInterviewOptions, timezone },
        };
    }
    return state;
};

export const reschedule = (state: AppState, action: AppAction<{ reasonCode: string; note?: string }>): AppState => {
    if (action.type === AppActions.RESCHEDULE) {
        const meetIntro = state.meetIntro;

        if (meetIntro?.data?.interview?.status) {
            meetIntro.data.interview.status = InterviewStatus.RESCHEDULE;
        }

        return {
            ...state,
            meetIntro,
            rescheduleReason: action.payload,
        };
    }
    return state;
};

export const setUserActionTrackerReducer = (
    state: AppState,
    action: AppAction<SetUserActionTrackerActionInput>,
): AppState => {
    if (action.type === AppActions.SET_USER_ACTION) {
        const payload = action.payload;
        if (!payload) {
            return { ...state };
        }

        return {
            ...state,
            userActionTracker: computeNextUserTrackerState({
                hash: payload.hash,
                correlationId: payload.correlationId,
                org: payload.org,
                sessionId: payload.sessionId,
                resultCounts: state.interviewOptionsCounts,
                nextAction: payload.nextAction,
                currentState: state.userActionTracker,
                closeReason: payload.closeReason,
            }),
        };
    }
    return state;
};
