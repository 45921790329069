import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import cs from './locales/cs/cs.json';
import de from './locales/de/de.json';
import en from './locales/en-US/en.json';
import es from './locales/es/es.json';
import fr from './locales/fr/fr.json';
import it from './locales/it/it.json';
import ja from './locales/ja/ja.json';
import ko from './locales/ko/ko.json';
import nl from './locales/nl/nl.json';
import pl from './locales/pl/pl.json';
import pt from './locales/pt/pt.json';
import sw from './locales/sw/sw.json';
import zh from './locales/zh/zh.json';
import { logger } from './providers/monitor.provider';

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
        {
            ns: ['general', 'intro'],
            debug: false,
            resources: {
                'en-US': {
                    ...en,
                },
                es: {
                    ...es,
                },
                fr: {
                    ...fr,
                },
                it: {
                    ...it,
                },
                sw: {
                    ...sw,
                },
                zh: {
                    ...zh,
                },
                de: {
                    ...de,
                },
                nl: {
                    ...nl,
                },
                cs: {
                    ...cs,
                },
                pl: {
                    ...pl,
                },
                pt: {
                    ...pt,
                },
                ko: {
                    ...ko,
                },
                ja: {
                    ...ja,
                },
            },
            fallbackLng: 'en-US',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            react: {
                useSuspense: false,
            },
        },
        (err) => {
            if (err) {
                return logger.error('Error while loading i18n', err);
            }
        },
    );

export default i18n;
