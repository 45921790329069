import { type InterviewTemplate as EtchInterviewTemplate } from '@goodtimeio/storm/entities/etch';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

export interface Interview {
    status: string;
    events: EventTemplate[];
    availableOptions: AvailableOptions | undefined;
    title: string;
    startTime: string;
    endTime: string;
    recruiter: { name: string; description: string };
    candidate: { name: string };
    companyInfo: { name: string; description: string };
    startDate: string;
    endDate: string;
    shareItinerary: boolean;
    settings: InterviewSettings;
    minimumDurationMin: number;
    maximumDurationMin: number;
    biggerDuration: number;
    greetEvent?: ExternalEvent;
    walkoutEvent?: ExternalEvent;
    origin?: InterviewOrigin;
}

export enum InterviewOrigin {
    SUPERDAY = 'superday',
    HIGH_VOLUME = 'high-volume',
}

export interface ExternalEvent extends EventTemplate {
    id: string;
    durationMin: number;
    settings: { includeInExternalEvent: boolean };
}

export interface EventTemplate {
    duration: number;
    startTime: string;
    endTime: string;
    title: string;
    topic?: string;
}

export type InterviewOptions = InterviewOption[];

export interface InterviewOption {
    correlationId: string;
    timeSlots: TimeSlot[];
    hash: string;
}

export interface MultiDayInterviewOption {
    numberOfDays: number;
    interviewOptions: InterviewOption[];
    hash: string;
}

export interface SearchMetadataTimeRange {
    start: string;
    end: string;
}

export interface AttendeeRank {
    userId: string;
    email: string;
    timeZoneProximity: number;
    loadLimitReached: 1 | 0;
    interviewCount: number;
    interviewsLeftToGraduation?: number;
    enrollmentTime?: number;
    randomized?: boolean;
    available?: boolean;
}

export type EventRankMap = Record<string, AttendeeRank[][]>;

export interface SearchMetadata {
    correlationId: string;
    sessionId: string;
    wsEndpoint: string;
    timeRanges: SearchMetadataTimeRange[];
    meetingTemplate: MeetingTemplate;
    debugInfo?: DebugInfo;
    error?: string;
}

export interface DebugInfo {
    eventRankMap?: EventRankMap;
    attendees?: AttendeeInfo[];
}

export interface DebugState {
    debugMode: boolean;
    debugResults: Result[];
    filterOptions: FilterOptions;
    displayResults: Result[];
    eventRankMap?: EventRankMap; // all pool's rank for each event
    userMap: UserMap;
}

export type UserMap = Map<string, AttendeeInfo>;
export type DebugFilter = (result: Result) => boolean;
export interface FilterOptions {
    term?: string;
    filterBySelectedSlots: boolean;
}

export interface AttendeeInfo {
    id: string;
    name: string;
}

export interface MeetingTemplate {
    startTimeInterval: number;
}

export interface TimeSlot {
    date: string;
    startTime: string;
    endTime: string;
    isAvailable: boolean;
    hash: string;
}

export interface AvailableOptions {
    singleDay: InterviewOption[];
    multiDay: MultiDayInterviewOption[];
}

export interface Resource<T> {
    gtErrorName?: ApiErrorTypes;
    isLoading: boolean;
    data: T | undefined;
    error?: string;
}

export type SelectedOption = InterviewOption;
export type SelectedTimeSlots = TimeSlot[];

export interface InterviewSettings {
    allowGuestToSuggestTimes: boolean;
    needsReview: boolean;
    timeZone: string;
    firstDate: string;
    lastDate: string;
    availableTimeRanges: AvailableTimeRange[];
    guestRescheduleReasons?: StoreRescheduleReason[];
    isOnsite: boolean;
}

export type AvailableTimeRange = Pick<TimeRange, 'startTime' | 'endTime'>;
export type SearchRanges = { startTime: string; endTime: string };

export interface User {
    id?: string;
    givenName: string;
    familyName: string;
    fullName: string;
    profileUrl?: string;
    title?: string;
    email: string;
}

export interface Guest extends User {
    settings: {
        timeZone: string;
    };
}

export interface RescheduleReason {
    note?: string;
    reasonCode: string;
}

export enum UserAgent {
    REQUEST_AVAILABILITY = 'GoodTime-request-availability',
    SUPERDAY_REQUEST_AVAILABILITY = 'GoodTime-superday-request-availability',
}

export enum LocalStorageProperty {
    UserAgent = 'UserAgent',
}

export interface StoreRescheduleReason {
    text?: string;
    reasonCode: string;
}

export interface Organization {
    id?: string;
    name: string;
    domain?: string;
    website: string;
    legalName?: string;
    logoUrl: string;
    settings: {
        guestRescheduleReasons: RescheduleReason[];
        allowToSuggestTimesFeatureFlag?: boolean;
        updatedDurationFeatureFlag?: boolean;
        hideEmailOnRequestAvailability?: boolean;
    };
}

export interface MeetIntro {
    guest: Guest;
    emailSender: User;
    organization: Organization;
    meetBackgroundUrl: string;
    meetBackgroundUrlList: string[];
    meetIntroHeader: string;
    meetIntroSubheader: string;
    meetAboutCompany?: string;
    meetIntroScheduleHeader?: string;
    meetInterviewConfirmedHeader?: string;
    meetInterviewConfirmedMessage?: string;
    meetItineraryHeader?: string;
    meetCompanyColor?: string;
    meetFontColor?: string;
    canGuestReschedule: boolean;
    showInterviewSummaryToGuest: boolean;
    isPendingGuestResponse: boolean;
    interview: Interview;
    isPastTimeWindow: boolean;
    searchRanges: SearchRanges[];
    hasError?: boolean;
    errCode?: number;
    gtErrorName?: string;
}

export interface Duration {
    startTime: string;
    endTime: string;
    durationTime: string;
}

export interface TimeRange {
    startTime: string;
    endTime: string;
    durationMin: number;
}

export interface HashedOption {
    hash: string;
}

export interface SchedulingLink {
    id?: string;
    organizationId: string;
    atsJobId: string;
    atsStageId: string;
    atsStepIds?: string[] | null;
    interviewTemplateName: string;
    etchInterviewTemplateId: string;
    staticLink: string;
    etchUserId: string;
    shouldUseInterviewBlock: boolean;
    settings: Settings;
    status: Status;
    createdAt?: string;
    updatedAt?: string;
    archivedAt?: string | null;
    etchInterviewTemplate?: EtchInterviewTemplate;
}

export interface Settings {
    generateCandidate: boolean;
    filterCandidates: boolean;
    collectLinkedIn: boolean;
}

export interface SchedulingLinkIntroResponse {
     
    schedulingLink: SchedulingLink;
    etchBrandTheme: etchBrandTheme;
}

export interface MessageFromPortalDTO {
    organizationId: string;
    guestId: string;
    message: string;
}

export enum MessageType {
    SMS = 'SMS',
    WA = 'WA',
    EMAIL = 'EMAIL',
    LAST_CHANNEL = 'LAST_CHANNEL',
    SYSTEM = 'SYSTEM',
    CANDIDATE_PORTAL = 'CANDIDATE_PORTAL',
}

export enum MessageMailerType {
    SENT = 'SENT',
    RECEIVED = 'RECEIVED',
    SYSTEM = 'SYSTEM',
}

export interface Message {
    message?: string | null;
    createdAt: string;
    mailerType: MessageMailerType;
    messageType: MessageType;
}

export interface PortalMessagesResponse {
    messages: Message[];
}

export interface etchBrandTheme {
    brandName?: string;
    logoUrl: string;
    meetBackgroundUrl: string;
    meetBackgroundUrlList: string[];
    meetCompanyColor?: string;
    meetFontColor?: string;
    linkedin: string | null;
    facebook: string | null;
    x: string | null;
    instagram: string | null;
    website: string | null;
}

export enum Status {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
    DISABLED = 'disabled',
}

export interface SchedulingLinkDataToSend {
    staticLink: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    linkedInUrl?: string | null;
    source?: string | null;
}

export interface SchedulingLinkDataToSendResponse {
    interview?: InterviewSchedulingLinkResponse;
}

export interface InterviewSchedulingLinkResponse {
    id: string;
    hash: string;
    url: string;
    guests: GuestSchedulingLinkResponse;
}

export interface GuestSchedulingLinkResponse {
    id: string;
    fullName: string;
    email: string;
}

export type SingleDayMap = Map<string, SingleDayOption[]>;
export type MultiDayMap = Map<number, MultiDayOption[]>;

export interface SingleDayOption extends HashedOption {
    isSuggested?: boolean;
    duration: Duration;
    timeCategory: {
        label: string;
        key: string;
        icon: IconDefinition;
    };
    iteration?: number;
    interviewBlockId?: string;
    interviewBlockStartTime?: string;
    interviewTimeBlockIndex?: number;
}

export interface MultiDayOption extends HashedOption {
    duration: Duration[];
}

export type MultiDayOptionGroup = Map<number, MultiDayOption[]>;
export type SingleDayOptionGroup = Map<string, SingleDayOption[]>;

export interface AvailableInterviewOptions {
    singleDay: SingleDayOptionGroup;
    multiDay: MultiDayOptionGroup;
    timezone: string;
}

export type InterviewOptionsCounts = {
    total: number;
    available: number;
};

type ScoreHard = number;
type ScoreMedium = number;
type ScoreSoft = number;
type ScoreMinor = number;
export type Score = [ScoreHard, ScoreMedium, ScoreSoft, ScoreMinor];

export type EventType = 'DEFAULT' | 'OPENING' | 'CLOSING';

export interface Result {
    id: string;
    correlationId: string;
    hash: string;
    startTime: string;
    timezone: string;
    score: Score;
    weightedScoreLong: number;
    events: EventResult[];
    justifications: Justification[];
    feasible: boolean;
    weightedScoreDouble: number;
}

export interface SuperdayOption {
    startTime: string;
    endTime: string;
    durationTime: number;
    iteration: number;
    interviewBlockId: string;
    interviewBlockStartTime?: string;
    interviewTimeBlockIndex?: number;
}

export interface SuperdayResult {
    date: string;
    options: SuperdayOption[];
}

export interface SuperdayAvailability {
    iteration?: number;
    interviewBlockId?: string;
    startTime?: string;
    endTime?: string;
}

export interface Justification {
    id: string;
    score: Score;
    items?:
        | JustificationItem
        | EventAttendeeSoftConflictItem
        | CorrectlyDistributeIntoDaysItem
        | EventWithinAttendeeHourItem
        | SelectAttendeesWithLowerLoadItem
        | ReoccurringAttendeeInMeetingItem;
}

export interface JustificationItem {
    eventId?: string;
    attendeeId?: string;
    eventType?: EventType;
    isOptionalAttendee?: boolean;
    isOptionalEvent?: boolean;
    startTime?: string;
}

export interface EventAttendeeSoftConflictItem extends JustificationItem {
    expected?: number;
    received?: number;
    calendarEvents: CalendarEvent[];
    calendarEventIds: string[];
}

export interface CorrectlyDistributeIntoDaysItem extends JustificationItem {
    expected: number;
    received: number;
}

export interface EventWithinAttendeeHourItem extends JustificationItem {
    attendeeHours: string[];
}

export interface SelectAttendeesWithLowerLoadItem extends JustificationItem {
    counts: ItemCount[];
}

type ItemCount = [string, string, number];

export interface ReoccurringAttendeeInMeetingItem extends JustificationItem {
    isAllowDuplicateContactsAcrossEvents: boolean;
    optionalEventIds: string[];
    eventIds: string[];
}

export interface CalendarEvent {
    id: string;
    calendarEventId: string;
}

export interface EventResult {
    eventTemplateId: string;
    attendees: string[];
    rooms: string[];
    startTime: string;
    endTime: string;
    order: number;
    desiredOrder: number;
    hasSpecificDate: boolean;
    hasFixedTimeRange: boolean;
    optional: boolean;
    type: EventType;
    locked: boolean;
}

export interface ConfirmCandidateAvailabilityBody {
    guestTimeZone: string;
    availableTimeRanges: TimeRange[];
    resultOption?: Result;
}

export interface RescheduleCandidateAvailabilityBody {
    guestTimeZone: string;
    availableTimeRanges: TimeRange[];
    note?: string;
    reasonCode: string;
    resultOption: Result | undefined;
}

export enum InterviewStatus {
    QUEUED = 'queued',
    HOLD = 'hold',
    PENDING = 'pending',
    PENDING_REVIEW = 'pending_review',
    PENDING_CONTACT_RESPONSE = 'pending_contact_response',
    PENDING_CANDIDATE_RESPONSE = 'pending_candidate_response',
    CONFLICT = 'conflict',
    CONFIRMED = 'confirmed',
    COMPLETED = 'completed',
    CANCELED = 'canceled',
    NEED_ACTION = 'needsAction',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
    TENTATIVE = 'tentative',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    NONE = 'needsAction',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    TENTATIVELY_ACCEPTED = 'tentative',
    RESCHEDULE = 'reschedule',
}

export enum ApiErrorTypes {
    REQUEST_AVAILABILITY_TIME_WINDOW_EXPIRED = 'REQUEST_AVAILABILITY_TIME_WINDOW_EXPIRED',
    CANCELED_INTERVIEW = 'CANCELED_INTERVIEW',
    EXPIRED_INTERVIEW = 'EXPIRED_INTERVIEW',
    INVALID_AVAILABLE_TIME_RANGES = 'INVALID_AVAILABLE_TIME_RANGES',
    INTERVIEW_NOT_FOUND = 'INTERVIEW_NOT_FOUND',
    UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface BrandString {
    locale: string;
    text: string;
    default: boolean;
}

export interface ApiResponse<T> {
    status: string;
    requestId: string;
    code: number;
    gtErrorCode?: number;
    result: T;
}

export type UserActionState = {
    hash?: string;
    sessionId?: string;
    correlationId?: string;
    previousAction?: UserAction;
    currentAction: UserAction;
    currentActionDuration?: number;
    org?: string;
} & {
    [key in UserAction]?: string;
} & {
    [key in UserAction as `${key}Epoch`]?: number;
} & UserActionStateMetrics;

export type UserActionStateMetrics = {
    perceivedTimeToFirstResult?: number; // firstValidSolution - (clickIntroButton - startSearch)
    perceivedInitialResultCount?: number; // number of results visible when clicking intro button
    searchToFirstResult?: number; // firstSolution - startSearch
    searchToFirstValidResult?: number; // firstValidSolution - startSearch
    allResultsCount?: number;
    validResultsCount?: number;
    socketCloseReason?: string;
    endState?: 'noResults' | 'socketDisconnected' | 'invalid';
};

export type UserAction =
    | 'start'
    | 'startSearch'
    | 'firstSolution'
    | 'firstValidSolution'
    | 'confirmAvailability'
    | 'pickedFirstResult'
    | 'clickIntroButton'
    | 'socketDisconnected'
    | 'noResults'
    | 'invalid';

export type SetUserActionTrackerActionInput = {
    hash: string;
    correlationId?: string;
    org?: string;
    sessionId?: string;
    nextAction: UserAction;
    closeReason?: string;
};

export * from './exceptions';
