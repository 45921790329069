import { DateTime } from 'luxon';
import { InterviewOptionsCounts, UserAction, UserActionState, UserActionStateMetrics } from '../../types';

type ComputeUserTrackerStateInput = {
    hash: string;
    correlationId?: string;
    org?: string;
    sessionId?: string;
    resultCounts: InterviewOptionsCounts;
    nextAction: UserAction;
    currentState: UserActionState;
    closeReason?: string;
};

export const computeNextUserTrackerState = (input: ComputeUserTrackerStateInput): UserActionState => {
    const previousAction = input.currentState.currentAction;
    const previousActionEpoch = input.currentState[`${previousAction}Epoch`];
    const nextAction = input.nextAction ?? 'invalid';

    const currentDate = DateTime.now().toUTC();
    const nextActionEpoch = currentDate.toMillis();

    const duration = previousActionEpoch && nextActionEpoch ? nextActionEpoch - previousActionEpoch : -1;

    const newState = {
        ...input.currentState,
        hash: input.currentState.hash ?? input.hash,
        correlationId: input.currentState.correlationId ?? input.correlationId,
        org: input.currentState.org ?? input.org,
        sessionId: input.currentState.sessionId ?? input.sessionId,
        currentAction: nextAction,
        currentActionDuration: duration,
        [`${nextAction}Epoch`]: nextActionEpoch,
        previousAction,
    };

    const newStateMetrics = computeNextActionMetrics({
        action: nextAction,
        trackingState: newState,
        resultCounts: input.resultCounts,
        closeReason: input.closeReason,
    });

    return {
        ...newState,
        ...newStateMetrics,
    };
};

export const computeNextActionMetrics = ({
    action,
    trackingState,
    resultCounts,
    closeReason,
}: {
    action: UserAction;
    trackingState: UserActionState;
    resultCounts: InterviewOptionsCounts;
    closeReason?: string;
}): UserActionStateMetrics => {
    const { startSearchEpoch, clickIntroButtonEpoch, firstSolutionEpoch, firstValidSolutionEpoch } = trackingState;

    const perceivedTimeToFirstResult =
        clickIntroButtonEpoch && firstValidSolutionEpoch ? firstValidSolutionEpoch - clickIntroButtonEpoch : undefined;
    const searchToFirstResult =
        startSearchEpoch && firstSolutionEpoch ? firstSolutionEpoch - startSearchEpoch : undefined;
    const searchToFirstValidResult =
        startSearchEpoch && firstValidSolutionEpoch ? firstValidSolutionEpoch - startSearchEpoch : undefined;

    const allResultsCount = resultCounts.total;
    const validResultsCount = resultCounts.available;

    switch (action) {
        case 'firstSolution':
        case 'firstValidSolution':
        case 'clickIntroButton':
            return {
                searchToFirstResult: trackingState.searchToFirstResult ?? searchToFirstResult,
                searchToFirstValidResult: trackingState.searchToFirstValidResult ?? searchToFirstValidResult,
                perceivedTimeToFirstResult: trackingState.perceivedTimeToFirstResult ?? perceivedTimeToFirstResult,
                perceivedInitialResultCount:
                    trackingState.perceivedInitialResultCount ?? action === 'clickIntroButton'
                        ? resultCounts.available
                        : undefined,
            };
        case 'noResults':
        case 'socketDisconnected':
            return {
                allResultsCount,
                validResultsCount,
                socketCloseReason: trackingState.socketCloseReason ?? closeReason,
                endState: action,
            };
        default:
            return {};
    }
};
