/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppAction } from './actions';
import {
    AvailableInterviewOptions,
    BrandString,
    DebugState,
    FilterOptions,
    Interview,
    InterviewOptionsCounts,
    MeetIntro,
    MultiDayOption,
    RescheduleReason,
    Resource,
    Result,
    SchedulingLinkDataToSend,
    SchedulingLinkDataToSendResponse,
    SchedulingLinkIntroResponse,
    SearchMetadata,
    SingleDayOption,
    SuperdayResult,
    UserAction,
    UserActionState,
    UserAgent,
} from '../../types';
import { DEFAULT_LOCALE, mapToSupportedLocale } from '../../utils/locales';
import { DateTime } from 'luxon';
import { InterviewerAvailabilityData, LinkAvailabilityData } from '../../pages/Superday/types';
import {
    addDays,
    addSuperdayTimezone,
    addTimeSlot,
    addTimeSlots,
    addTimezone,
    getCSRFToken,
    receiveAlgoResults,
    receiveApiError,
    receiveAvailableInterviewOptions,
    receiveInterview,
    receiveMeetIntro,
    receiveSchedulingLinkIntro,
    receiveSearchMetadata,
    receiveSuperdayResults,
    reschedule,
    sendSchedulingLinkData,
    setDebugMode,
    setFilterOptions,
    setInterviewerAvailabilityData,
    setLinkAvailabilityData,
    setUserActionTrackerReducer,
    updateSelectedLanguage,
} from './reducers';
export * from './actions';

export interface AppState {
    interview: Interview | undefined;
    searchMetadata: Resource<SearchMetadata> | undefined;
    meetIntro: Resource<MeetIntro> | undefined;
    selectedDays: string[] | undefined;
    selectedTimeSlots: (string | undefined)[] | undefined;
    selectedLanguage: string;
    results: Result[];
    superdayResults: SuperdayResult[];
    availableInterviewOptions: AvailableInterviewOptions;
    interviewOptionsCounts: InterviewOptionsCounts;
    token: string | undefined;
    lastResultReceivedTime: DateTime | undefined;
    rescheduleReason: RescheduleReason | undefined;
    schedulingLinkIntro: Resource<SchedulingLinkIntroResponse> | undefined;
    schedulingLinkDataSent: Resource<SchedulingLinkDataToSendResponse> | undefined;
    userAgentHeader: UserAgent;
    userActionTracker: UserActionState;
    linkAvailabilityData: Resource<LinkAvailabilityData> | undefined;
    interviewerAvailabilityData: Resource<InterviewerAvailabilityData> | undefined;
    debug: DebugState;
    addDays: (selectedDays: string[]) => void;
    addTimeSlot: (selectedTimeSlots: string) => void;
    addTimeSlots: (selectedTimeSlots: string[]) => void;
    addTimezone: (timezone: string) => void;
    addSuperdayTimezone: (timezone: string) => void;
    updateSelectedLanguage: (language: string) => void;
    getCSRFToken: () => void;
    loadAvailabilitySearch: (hash: string) => void;
    bootstrapApp: (hash: string, source?: string | null, debug?: boolean) => void;
    bootstrapSuperdayApp: (hash: string, source?: string | null) => void;
    getMeetIntro: (hash: string) => void;
    postConfirmCandidateAvailability: (hash: string) => void;
    postConfirmSuperdayAvailability: (hash: string) => void;
    postCandidateReschedule: (hash: string) => void;
    translate: (valueToTranslate: string, dynamicValueObject?: Record<string, unknown>) => string;
    getValueForBrandStringField: (brandStringArray: string | BrandString[] | undefined, keyToCheck?: string) => string;
    reschedule: (reasonCode: string, note?: string) => void;
    receiveSuperdayResults: (results: SuperdayResult[]) => void;
    getSchedulingLinkIntro: (link: string) => void;
    sendSchedulingLinkData: (data: SchedulingLinkDataToSend) => void;
    setUserAgentHeader: (userAgent: UserAgent) => void;
    storeAndSendUserAction: (hash: string, propertyName: UserAction) => void;
    setLinkAvailabilityData: (data: LinkAvailabilityData) => void;
    setInterviewerAvailabilityData: (data: InterviewerAvailabilityData) => void;
    setDebugMode: (debugMode: boolean) => void;
    setFilterOptions: (filterOptions: FilterOptions) => void;
}

// eslint-disable-next-line
const defaultVoidHandler = (): void => {};
const defaultStringHandler = (): string => '';

export const initialState: AppState = {
    interview: undefined,
    searchMetadata: undefined,
    meetIntro: undefined,
    availableInterviewOptions: {
        multiDay: new Map<number, MultiDayOption[]>(),
        singleDay: new Map<string, SingleDayOption[]>(),
        timezone: '',
    },
    interviewOptionsCounts: {
        total: 0,
        available: 0,
    },
    token: undefined,
    results: [],
    superdayResults: [],
    selectedDays: [],
    selectedTimeSlots: [],
    linkAvailabilityData: undefined,
    interviewerAvailabilityData: undefined,
    selectedLanguage: mapToSupportedLocale(navigator.language ?? DEFAULT_LOCALE),
    lastResultReceivedTime: undefined,
    rescheduleReason: { note: '', reasonCode: '' },
    schedulingLinkIntro: undefined,
    schedulingLinkDataSent: undefined,
    userAgentHeader: UserAgent.REQUEST_AVAILABILITY,
    userActionTracker: {
        start: DateTime.now().toUTC().toISO(),
        startEpoch: DateTime.now().toUTC().toMillis(),
        currentActionDuration: 0,
        currentAction: 'start',
    },
    debug: {
        debugMode: false,
        debugResults: [],
        filterOptions: {
            term: undefined,
            filterBySelectedSlots: true,
        },
        displayResults: [],
        userMap: new Map(),
    },
    addDays: defaultVoidHandler,
    addTimeSlot: defaultVoidHandler,
    addTimeSlots: defaultVoidHandler,
    addTimezone: defaultVoidHandler,
    addSuperdayTimezone: defaultVoidHandler,
    getCSRFToken: defaultVoidHandler,
    loadAvailabilitySearch: defaultVoidHandler,
    bootstrapApp: defaultVoidHandler,
    bootstrapSuperdayApp: defaultVoidHandler,
    getMeetIntro: defaultVoidHandler,
    postConfirmCandidateAvailability: defaultVoidHandler,
    postConfirmSuperdayAvailability: defaultVoidHandler,
    postCandidateReschedule: defaultVoidHandler,
    translate: defaultStringHandler,
    getValueForBrandStringField: defaultStringHandler,
    reschedule: defaultStringHandler,
    getSchedulingLinkIntro: defaultVoidHandler,
    sendSchedulingLinkData: defaultVoidHandler,
    setUserAgentHeader: defaultVoidHandler,
    receiveSuperdayResults: defaultVoidHandler,
    storeAndSendUserAction: defaultVoidHandler,
    setLinkAvailabilityData: defaultVoidHandler,
    setInterviewerAvailabilityData: defaultVoidHandler,
    setDebugMode: defaultVoidHandler,
    setFilterOptions: defaultVoidHandler,
    updateSelectedLanguage: (): string => {
        return 'en';
    },
};

const reducerMap = {
    receiveInterview,
    receiveSearchMetadata,
    receiveMeetIntro,
    receiveAlgoResults,
    receiveSuperdayResults,
    receiveAvailableInterviewOptions,
    receiveApiError,
    addDays,
    addTimeSlot,
    addTimeSlots,
    addTimezone,
    addSuperdayTimezone,
    setFilterOptions,
    updateSelectedLanguage,
    getCSRFToken,
    reschedule,
    receiveSchedulingLinkIntro,
    sendSchedulingLinkData,
    setUserActionTrackerReducer,
    setLinkAvailabilityData,
    setInterviewerAvailabilityData,
    setDebugMode,
};

type keyOfMap = keyof typeof reducerMap;

export const reducers = (state: AppState, action: AppAction<any>): AppState => {
    return Object.keys(reducerMap).reduce((state, reducer: string) => {
        return reducerMap[reducer as keyOfMap](state, action);
    }, state);
};
