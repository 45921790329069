import { AvailableTimeRange, EventTemplate, Interview, TimeRange } from '../types';
import { DateTime } from 'luxon';
import { IconDefinition, faMoon, faSnooze, faSun, faSunrise, faSunset } from '@fortawesome/pro-solid-svg-icons';

export const getTimeSimple = (date: DateTime): string => {
    return date.toLocaleString(DateTime.TIME_SIMPLE);
};

export const getDateShort = (date: DateTime, locale?: string): string => {
    return date.toLocaleString(DateTime.DATE_SHORT, locale ? { locale } : {});
};

export const getDateTimeMed = (date: DateTime, locale?: string): string => {
    return date.toLocaleString(DateTime.DATETIME_MED, locale ? { locale } : {});
};

export const getDateHuge = (date: DateTime, locale?: string): string => {
    return date.toLocaleString(DateTime.DATE_HUGE, locale ? { locale } : {});
};

export const getDayMonthDate = (date: DateTime): string => {
    if (date?.locale === 'ko') {
        return date.toFormat('EEEE M월 d일');
    }
    return date.toFormat('EEEE, MMMM dd');
};

export const getMonthAbbreviated = (date: DateTime): string => {
    return date.toFormat('MMM');
};

export const getDayAbbreviated = (date: DateTime): string => {
    return date.toFormat('EEE');
};

export const getDayOfMonth = (date: DateTime): string => {
    return date.toFormat('dd');
};

export const getDayOfMonthNoPadding = (date: DateTime): string => {
    return date.toFormat('d');
};

export const getTimeCategory = (date: DateTime): { label: string; key: string; icon: IconDefinition } => {
    if (date.hour >= 0 && date.hour <= 6) {
        return { label: 'Early Morning', key: 'earlyMorning', icon: faSnooze };
    }

    if (date.hour > 6 && date.hour <= 12) {
        return { label: 'Morning', key: 'morning', icon: faSunrise };
    }

    if (date.hour > 12 && date.hour <= 18) {
        return { label: 'Afternoon', key: 'afternoon', icon: faSun };
    }

    if (date.hour > 18 && date.hour <= 21) {
        return { label: 'Evening', key: 'evening', icon: faSunset };
    }

    return { label: 'Night', key: 'night', icon: faMoon };
};

export const mapAvailableTimeRangeToTimeRange = ({ startTime, endTime }: AvailableTimeRange): TimeRange => ({
    startTime: startTime,
    endTime: endTime,
    durationMin: DateTime.fromISO(endTime).diff(DateTime.fromISO(startTime), ['minutes']).toObject().minutes || 0,
});

export const mapEventTemplateToTimeRange = ({ startTime, endTime, duration }: EventTemplate): TimeRange => ({
    startTime: startTime,
    endTime: endTime,
    durationMin: duration,
});

export const getTotalDaysBetweenTwoDates = (startDate: string, endDate: string): number => {
    const date1 = DateTime.fromISO(startDate).plus({ days: -2 });
    const date2 = DateTime.fromISO(endDate).plus({ days: 2 });
    if (date1 >= date2) {
        return 0;
    }

    return Math.ceil(date2.diff(date1, 'days').days);
};

export const getTimezone = (interview: Interview | undefined): string => {
    if (interview?.settings.isOnsite && interview?.settings?.timeZone) {
        return interview?.settings?.timeZone;
    }
    return DateTime.local().zoneName;
};
