import { AppContext } from '../context';
import { Route, Switch } from 'react-router-dom';
import Loading from '../components/Loading';
import React, { Suspense, lazy, useContext } from 'react';

const RequestAvailabilityRoutes = lazy(() => import('./RequestAvailabilityRoutes'));
const SuperdayRARoutes = lazy(() => import('./SuperdayAppRoutes'));

const AppRoutes: React.FC = () => {
    const { meetIntro } = useContext(AppContext);
    const backgroundImage = meetIntro?.data?.meetBackgroundUrlList?.[0] ?? '';

    return (
        <Suspense fallback={<Loading backgroundImage={backgroundImage} message={''} />}>
            <Switch>
                <Route path="/i" component={SuperdayRARoutes} />
                <Route path="/s" component={SuperdayRARoutes} />
                <Route path="*" component={RequestAvailabilityRoutes} />
            </Switch>
        </Suspense>
    );
};

export default AppRoutes;
