import './index.css';
import './providers/monitor.provider';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <App />
    </I18nextProvider>,
    document.getElementById('root') as HTMLElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
