import { AppContext } from '../../context';
import React, { useContext } from 'react';

import './styles.scss';

interface LoadingProps {
    message?: string;
    backgroundImage?: string;
}

const Loading: React.FC<LoadingProps> = ({ message = '', backgroundImage }) => {
    const { translate } = useContext(AppContext);

    const LOADING_STYLES = {
        backgroundImage: `url("${backgroundImage}")`,
    };

    message = message ? message : translate('intro:calculatingAndBuildingInterviewSchedules');

    return (
        <div className="container" style={LOADING_STYLES}>
            <div className="loadingContainer">
                <div className="loadingArea">
                    <div className="spinner">
                        <svg
                            className="logo"
                            width="64"
                            height="64"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                className="path"
                                d="M28 10H23.5V12C23.5 13.9891 22.7098 15.8968 21.3033 17.3033C19.8968 18.7098 17.9891 19.5 16 19.5C14.0109 19.5 12.1032 18.7098 10.6967 17.3033C9.29017 15.8968 8.5 13.9891 8.5 12C8.48347 11.5633 8.52381 11.1263 8.62 10.7C8.69827 10.1311 8.557 9.55365 8.22489 9.08511C7.89279 8.61656 7.39477 8.29203 6.83201 8.17745C6.26925 8.06286 5.68398 8.16681 5.19511 8.46819C4.70624 8.76956 4.35044 9.24575 4.2 9.8C4.05775 10.5245 3.99073 11.2617 4 12C4 15.1826 5.26428 18.2348 7.51472 20.4853C9.76515 22.7357 12.8174 24 16 24C19.1826 24 22.2348 22.7357 24.4853 20.4853C26.7357 18.2348 28 15.1826 28 12"
                                fill="#fff"
                            />
                        </svg>
                        <svg
                            className="logo2"
                            width="64"
                            height="64"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                className="path"
                                d="M28 10H23.5V12C23.5 13.9891 22.7098 15.8968 21.3033 17.3033C19.8968 18.7098 17.9891 19.5 16 19.5C14.0109 19.5 12.1032 18.7098 10.6967 17.3033C9.29017 15.8968 8.5 13.9891 8.5 12C8.48347 11.5633 8.52381 11.1263 8.62 10.7C8.69827 10.1311 8.557 9.55365 8.22489 9.08511C7.89279 8.61656 7.39477 8.29203 6.83201 8.17745C6.26925 8.06286 5.68398 8.16681 5.19511 8.46819C4.70624 8.76956 4.35044 9.24575 4.2 9.8C4.05775 10.5245 3.99073 11.2617 4 12C4 15.1826 5.26428 18.2348 7.51472 20.4853C9.76515 22.7357 12.8174 24 16 24C19.1826 24 22.2348 22.7357 24.4853 20.4853C26.7357 18.2348 28 15.1826 28 12"
                                fill="#ccc"
                            />
                        </svg>
                        <div className="tongue"></div>
                        <div className="tongue2"></div>
                    </div>
                </div>
                <p className="loadingText">{message}</p>
            </div>
        </div>
    );
};

export default Loading;
