import { Justification, Result } from '../types';

export enum DefaultEventConstraint {
    EVENT_ATTENDEE_CONFLICT = 'EVENT_ATTENDEE_CONFLICT',
    EVENT_ATTENDEE_SOFT_CONFLICT = 'EVENT_ATTENDEE_SOFT_CONFLICT',
    EVENT_ATTENDEE_CONFLICT_BUFFER = 'EVENT_ATTENDEE_CONFLICT_BUFFER',
    EVENT_ATTENDEE_SOFT_CONFLICT_BUFFER = 'EVENT_ATTENDEE_SOFT_CONFLICT_BUFFER',
    EVENT_ATTENDEE_OVER_MEETING_LIMIT = 'EVENT_ATTENDEE_OVER_MEETING_LIMIT',
    EVENT_WITHIN_CALENDAR_AVAILABILITY = 'EVENT_WITHIN_CALENDAR_AVAILABILITY',
    EVENT_WITHIN_ATTENDEE_HOURS = 'EVENT_WITHIN_ATTENDEE_HOURS',
    EVENT_WITHIN_TEMPLATE_HOURS = 'EVENT_WITHIN_TEMPLATE_HOURS',
    GAPS_BETWEEN_MIN_AND_MAX = 'GAPS_BETWEEN_MIN_AND_MAX',
    REOCCURRING_ATTENDEE_IN_MEETING = 'REOCCURRING_ATTENDEE_IN_MEETING',
    EVENT_WITHIN_MEAL_HOURS = 'EVENT_WITHIN_MEAL_HOURS',
}

export enum GlobalEventConstraint {
    GAPS_BETWEEN_MIN_AND_MAX = 'GAPS_BETWEEN_MIN_AND_MAX',
}

export enum NeedsReviewEventConstraint {
    EVENT_ROOM_CONFLICT = 'EVENT_ROOM_CONFLICT',
}

export type EnumToMap<T> = {
    -readonly [K in keyof T]: T[K];
};

type DefaultEventConstraintMap = EnumToMap<typeof DefaultEventConstraint>;
type OptionalEventConstraintMap = EnumToMap<typeof GlobalEventConstraint>;
type NeedsReviewEventConstraintMap = EnumToMap<typeof NeedsReviewEventConstraint>;

const defaultEventConstraintMap = Object.entries(DefaultEventConstraint).reduce(
    (map, [value, key]) => ({ ...map, [key]: value }),
    {} as DefaultEventConstraintMap,
);
const globalEventConstraintMap = Object.values(GlobalEventConstraint).reduce(
    (map, value) => ({ ...map, [value]: value }),
    {} as OptionalEventConstraintMap,
); 

const needsReviewEventConstraintMap = Object.values(NeedsReviewEventConstraint).reduce(
    (map, value) => ({ ...map, [value]: value }),
    {} as NeedsReviewEventConstraintMap,
);

export const raConstraintsFilter = (result: Result, needsReview?: boolean): boolean => {
    const invalidResult = result.justifications.some((justification) => requestAvailabilityConflict(justification, needsReview));
    return (
        // searching for some justifications with valid RA conflict
        result.feasible && !invalidResult
    );
};

const requestAvailabilityConflict = (justification: Justification, needsReview: boolean = false) =>
    // check if its a global conflict (aka conflict that applies to all events) or
    // is not a conflict of an optional attendee
    justification.id in globalEventConstraintMap || isEventWithConflict(justification) || (!needsReview && isEventWithNeedsReviewConflict(justification) );

const isEventWithConflict = (justification: Justification) => {
    const isOptionalAttendee = justification.items?.isOptionalAttendee === true;
    const isOptionalEvent = justification.items?.isOptionalEvent === true;
    return !(isOptionalEvent || isOptionalAttendee) && justification.id in defaultEventConstraintMap;
};

const isEventWithNeedsReviewConflict = (justification: Justification) => {
    return justification.id in needsReviewEventConstraintMap;
};

export const RAConstraintsMap = new Map([
    ['AVOID_GAPS_FOR_ORPHAN_EVENTS', 'Avoid gaps for orphan events'],
    ['CORRECTLY_DISTRIBUTE_INTO_DAYS', 'Correctly distribute into days'],
    ['EVENT_ATTENDEE_CONFLICT', 'Event attendee conflict'],
    ['EVENT_ATTENDEE_CONFLICT_BUFFER', 'Event attendee conflict buffer'],
    ['EVENT_ATTENDEE_OVER_MEETING_LIMIT', 'Over meeting limit'],
    ['EVENT_ATTENDEE_SOFT_CONFLICT', 'Event attendee soft conflict'],
    ['EVENT_ATTENDEE_SOFT_CONFLICT_BUFFER', 'Event attendee soft conflict buffer'],
    ['EVENT_MUST_HAPPEN_WITHIN_FIXED_TIME_RANGE', 'Event must happen within fixed time range'],
    ['EVENT_ROOM_CONFLICT', 'Event room conflict'],
    ['EVENTS_SHOULD_START_AT_IDEAL_MEETING_INTERVALS', 'Events should start at ideal meeting intervals'],
    ['EVENT_WITHIN_CALENDAR_AVAILABILITY', 'Event within calendar availability'],
    ['EVENT_WITHIN_ATTENDEE_HOURS', 'Event within attendee hours'],
    ['EVENT_WITHIN_MEAL_HOURS', 'Event within meal hours'],
    ['EVENT_WITHIN_TEMPLATE_HOURS', 'Event within template hours'],
    ['EVENTS_SHOULD_HAPPEN_IN_SPECIFIED_ORDER', 'Events should happen in specified order'],
    ['EVENTS_SHOULD_NOT_OVERLAP', 'Events should not overlap'],
    ['FIRST_EVENT_SHOULD_HAPPEN_WITHIN_START_TIME_RANGE', 'First event should happen within start time range'],
    ['GAPS_BETWEEN_MIN_AND_MAX', 'Gaps between min and max'],
    ['MEETING_SHOULD_HAPPEN_WITHIN_SEARCHED_TIMES', 'Meeting should happen within searched times'],
    ['MIN_ATTENDEES_FROM_LABEL', 'Minimum attendees from label'],
    ['MINIMIZE_ROOM_CHANGES', 'Minimize room changes'],
    ['MINIMIZE_TOTAL_DURATION', 'Minimize total duration'],
    ['ORPHAN_EVENTS', 'Orphan events'],
    ['RECURRING_BREAKS_BETWEEN_EVENTS', 'Recurring breaks between events'],
    ['REOCCURRING_ATTENDEE_IN_EVENT', 'Reoccurring attendee in event'],
    ['REOCCURRING_ATTENDEE_IN_MEETING', 'Reoccurring attendee in meeting'],
    ['REOCCURRING_ROOM_IN_EVENT', 'Reoccurring room in event'],
    ['RESTRICTED_ATTENDEE_SELECTED', 'Restricted attendee selected'],
    ['SELECT_ATTENDEES_WITH_LOWER_LOAD', 'Select attendees with lower load'],
    ['SELECT_ATTENDEES_CLOSE_TO_MEETING_TIMEZONE', 'Select attendees close to meeting timezone'],
    ['SELECT_CORRECT_ATTENDEES_WHEN_ALLOWING_SAME_SELECTION', 'Select correct attendees when allowing same selection'],
    ['SELECT_ATTENDEES_CLOSE_TO_GRADUATION', 'Select attendees close to graduation]'],
]);
