import { BrandString, InterviewStatus, MeetIntro, Result, SingleDayOption, UserAction } from '../types';
import {
    addDays,
    addSuperdayTimezone,
    addTimeSlot,
    addTimeSlots,
    addTimezone,
    getCSRFToken,
    getMeetIntro,
    getSchedulingLinkIntro,
    postCandidateReschedule,
    postConfirmCandidateAvailability,
    postConfirmSuperdayAvailability,
    receiveAlgoResults,
    receiveSuperdayResults,
    reschedule,
    sendSchedulingLinkData,
    setDebugMode,
    setFilterOptions,
    setInterviewerAvailabilityData,
    setLinkAvailabilityData,
    setUserActionTrackerAction,
    setUserAgentAction,
    startSearch,
    updateSelectedLanguage,
} from './reducers/actions';
import { initialState, reducers } from './reducers';
import { raConstraintsFilter } from '../utils/ra-constraints';
import { setApiHost, startAvailabilitySearch } from '../api';
import { logger, setUser } from '../providers/monitor.provider';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useReducer } from 'react';

interface ProviderProps {
    children: React.ReactNode;
}

export const AppContext = React.createContext(initialState);

export const Provider = ({ children }: ProviderProps) => {
    const [state, dispatch] = useReducer(reducers, { ...initialState });
    const { t } = useTranslation();

    const translate = (valueToTranslate: string, dynamicValueObject?: Record<string, unknown>): string => {
        if (dynamicValueObject) {
            return t(valueToTranslate, {
                ...dynamicValueObject,
            });
        }
        return t(valueToTranslate);
    };

    const getValueForBrandStringField = (
        brandStringArray: string | BrandString[] | undefined,
        keyToCheck?: string,
    ): string => {
        const defaultReturnValue = '';
        if (Array.isArray(brandStringArray)) {
            const brandVal = getBrandVal(brandStringArray, state.selectedLanguage, defaultReturnValue);
            if (brandVal === defaultReturnValue) {
                let translatedValue = '';
                if (keyToCheck) {
                    translatedValue = translate(keyToCheck) || '';
                }
                return translatedValue ? translatedValue : getBrandVal(brandStringArray, 'en', defaultReturnValue);
            }
            return brandVal;
        }
        if (brandStringArray) {
            return brandStringArray;
        }
        return defaultReturnValue;
    };

    const getBrandVal = (brandStringArray: BrandString[], lang: string, defaulVal: string): string => {
        const localizedString = brandStringArray.find((brandString) => brandString.locale === lang);
        return localizedString ? localizedString.text : defaulVal;
    };

    const bootstrapSuperdayApp = useCallback(
        async (hash: string, source?: string | null) => {
            const getCSRFTokenAction = getCSRFToken(dispatch);
            const getMeetIntroAction = getMeetIntro(dispatch);
            if (source) {
                setApiHost(source);
            }

            await getMeetIntroAction(hash);
            await getCSRFTokenAction();
        },
        [dispatch],
    );

    const loadMeetIntro = async (hash: string, source?: string | null) => {
        const getCSRFTokenAction = getCSRFToken(dispatch);
        const getMeetIntroAction = getMeetIntro(dispatch);
        await getCSRFTokenAction();
        if (source) {
            setApiHost(source);
        }
        return getMeetIntroAction(hash);
    };

    const loadAvailabilitySearch = async (hash: string, debug: boolean = false) => {
        const startSearchAction = startSearch(dispatch);
        const receiveAlgoResultsAction = receiveAlgoResults(dispatch);

        let hasValidEvent = false;
        let hasFirstEvent = false;
        const searchMetadata = await startSearchAction(hash, debug);

        if (searchMetadata?.wsEndpoint) {
            buildStoreAndSendUserTrackerAction(
                hash,
                'startSearch',
                searchMetadata?.sessionId,
                searchMetadata?.correlationId,
            );

            startAvailabilitySearch({
                endpoint: searchMetadata?.wsEndpoint,
                correlationId: searchMetadata?.correlationId,
                sessionId: searchMetadata?.sessionId,
                onMessage: (event) => {
                    const results = JSON.parse(event.data) as Result[];

                    if (!hasFirstEvent) {
                        hasFirstEvent = true;
                        buildStoreAndSendUserTrackerAction(
                            hash,
                            'firstSolution',
                            searchMetadata?.sessionId,
                            searchMetadata?.correlationId,
                        );
                    }

                    if (!hasValidEvent) {
                        const filteredResults = results.filter((result) => raConstraintsFilter(result,  state?.meetIntro?.data?.interview?.settings?.needsReview)) ?? [];
                        if (filteredResults.length > 0) {
                            hasValidEvent = true;
                            buildStoreAndSendUserTrackerAction(
                                hash,
                                'firstValidSolution',
                                searchMetadata?.sessionId,
                                searchMetadata?.correlationId,
                            );
                        }
                    }
                    receiveAlgoResultsAction(results);
                },
                onDisconnect: (event: CloseEvent) => {
                    logger.info('socket disconnected');
                    buildStoreAndSendUserTrackerAction(
                        hash,
                        'socketDisconnected',
                        searchMetadata?.sessionId,
                        searchMetadata?.correlationId,
                        event.reason,
                    );
                },
                onConnect: () => {
                    logger.info('socket connected');
                },
            });
        }
    };

    const bootstrapApp = useCallback(
        async (hash: string, source?: string | null, debug?: boolean) => {
            const meetIntro = await loadMeetIntro(hash, source);
            setUser({
                id: meetIntro?.guest?.id,
                orgId: meetIntro?.organization?.id,
                orgDomain: meetIntro?.organization?.domain,
            });

            const shouldStartAvailabilitySearch = [
                InterviewStatus.PENDING_CANDIDATE_RESPONSE,
                InterviewStatus.RESCHEDULE,
                InterviewStatus.PENDING_REVIEW,
            ].includes((meetIntro?.interview?.status ?? '') as InterviewStatus);

            if (shouldStartAvailabilitySearch) {
                loadAvailabilitySearch(hash, debug);
            }
        },
        [dispatch],
    );

    const buildStoreAndSendUserTrackerAction = (
        hash: string,
        propertyName: UserAction,
        sessionId?: string,
        correlationId?: string,
        closeReason?: string,
    ) => {
        const setUserActionTracker = setUserActionTrackerAction(dispatch);

        const { searchMetadata, meetIntro } = state;

        setUserActionTracker({
            hash,
            correlationId: correlationId ?? searchMetadata?.data?.correlationId,
            org: meetIntro?.data?.organization?.domain ?? meetIntro?.data?.organization?.name ?? undefined,
            sessionId: sessionId ?? searchMetadata?.data?.sessionId,
            nextAction: propertyName,
            closeReason: closeReason,
        });
    };

    const buildPostConfirmCandidateAvailabilityAction = async (hash: string) => {
        const postConfirmCandidateAvailabilityAction = postConfirmCandidateAvailability(dispatch);

        const { selectedTimeSlots = [], availableInterviewOptions, token, meetIntro, results } = state;

        await postConfirmCandidateAvailabilityAction(
            hash,
            meetIntro?.data?.guest.settings.timeZone || '',
            selectedTimeSlots,
            availableInterviewOptions,
            results,
            token || '',
            meetIntro?.data as MeetIntro,
        );
    };

    const buildPostConfirmSuperdayAvailabilityAction = async (hash: string) => {
        const postConfirmCandidateSuperdayAction = postConfirmSuperdayAvailability(dispatch);

        const { selectedTimeSlots = [], token, meetIntro, availableInterviewOptions } = state;
        const selectedAvailabilityBlock: SingleDayOption[] = [];
        const singleDayOptions = Array.from(availableInterviewOptions.singleDay.values()).flat(1);
        selectedTimeSlots.forEach((timeSlot) => {
            if (timeSlot) {
                const selectedAvailability = singleDayOptions.find((option) => option.hash === timeSlot);
                if (selectedAvailability) {
                    selectedAvailabilityBlock.push(selectedAvailability);
                }
            }
        });
        await postConfirmCandidateSuperdayAction(
            hash,
            selectedAvailabilityBlock,
            token || '',
            meetIntro?.data as MeetIntro,
        );
    };

    const buildPostCandidateRescheduleAction = (hash: string) => {
        const postCandidateRescheduleAction = postCandidateReschedule(dispatch);

        const {
            rescheduleReason = { reasonCode: '', code: '' },
            selectedTimeSlots = [],
            availableInterviewOptions,
            token,
            results,
            meetIntro,
        } = state;

        return postCandidateRescheduleAction(
            hash,
            token || '',
            rescheduleReason,
            selectedTimeSlots,
            availableInterviewOptions,
            results,
            meetIntro?.data as MeetIntro,
        );
    };

    const actions = {
        addDays: addDays(dispatch),
        addTimeSlot: addTimeSlot(dispatch),
        addTimeSlots: addTimeSlots(dispatch),
        setFilterOptions: setFilterOptions(dispatch),
        startSearch: startSearch(dispatch),
        getMeetIntro: getMeetIntro(dispatch),
        receiveAlgoResults: receiveAlgoResults(dispatch),
        receiveSuperdayResults: receiveSuperdayResults(dispatch),
        addTimezone: addTimezone(dispatch),
        addSuperdayTimezone: addSuperdayTimezone(dispatch),
        updateSelectedLanguage: updateSelectedLanguage(dispatch),
        getCSRFToken: getCSRFToken(dispatch),
        bootstrapApp,
        loadAvailabilitySearch,
        bootstrapSuperdayApp,
        postConfirmCandidateAvailability: buildPostConfirmCandidateAvailabilityAction,
        postConfirmSuperdayAvailability: buildPostConfirmSuperdayAvailabilityAction,
        postCandidateReschedule: buildPostCandidateRescheduleAction,
        translate: translate,
        getValueForBrandStringField,
        reschedule: reschedule(dispatch),
        getSchedulingLinkIntro: getSchedulingLinkIntro(dispatch),
        sendSchedulingLinkData: sendSchedulingLinkData(dispatch),
        setUserAgentHeader: setUserAgentAction(dispatch),
        setUserActionTracker: setUserActionTrackerAction(dispatch),
        storeAndSendUserAction: buildStoreAndSendUserTrackerAction,
        setLinkAvailabilityData: setLinkAvailabilityData(dispatch),
        setInterviewerAvailabilityData: setInterviewerAvailabilityData(dispatch),
        setDebugMode: setDebugMode(dispatch),
    };

    const appStore = {
        interview: state.interview,
        selectedDays: state.selectedDays,
        selectedTimeSlots: state.selectedTimeSlots,
        selectedLanguage: state.selectedLanguage,
        searchMetadata: state.searchMetadata,
        meetIntro: state.meetIntro,
        results: state.results,
        superdayResults: state.superdayResults,
        availableInterviewOptions: state.availableInterviewOptions,
        interviewOptionsCounts: state.interviewOptionsCounts,
        token: state.token,
        lastResultReceivedTime: state.lastResultReceivedTime,
        rescheduleReason: state.rescheduleReason,
        schedulingLinkIntro: state.schedulingLinkIntro,
        schedulingLinkDataSent: state.schedulingLinkDataSent,
        userAgentHeader: state.userAgentHeader,
        userActionTracker: state.userActionTracker,
        linkAvailabilityData: state.linkAvailabilityData,
        interviewerAvailabilityData: state.interviewerAvailabilityData,
        debug: state.debug,
        ...actions,
    };
    return <AppContext.Provider value={appStore}>{children}</AppContext.Provider>;
};
