import {
    APIError,
    ApiErrorTypes,
    AvailableInterviewOptions,
    FilterOptions,
    InterviewStatus,
    LocalStorageProperty,
    MeetIntro,
    Resource,
    Result,
    SchedulingLinkDataToSend,
    SchedulingLinkDataToSendResponse,
    SchedulingLinkIntroResponse,
    SearchMetadata,
    SetUserActionTrackerActionInput,
    SingleDayOption,
    SuperdayAvailability,
    SuperdayResult,
    TimeRange,
    UserAgent,
} from '../../types';
import { DateTime } from 'luxon';
import { Dispatch } from 'react';
import { ERROR } from '../../types/errors';
import { InterviewerAvailabilityData, LinkAvailabilityData } from '../../pages/Superday/types';
import {
    confirmSuperdayAvailabilityApi,
    getCSRFTokenFromServerApi,
    getMeetIntroApi,
    getSchedulingLinkIntroApi,
    postCandidateRescheduleApi,
    postConfirmCandidateAvailabilityApi,
    sendSchedulingLinkDataApi,
    startSearchApi,
} from '../../api';

export enum AppActions {
    RECEIVE_INTERVIEW = 'receiveInterview',
    ADD_DAYS = 'addDays',
    ADD_TIME_SLOT = 'addTimeSlot',
    ADD_TIME_SLOTS = 'addTimeSlots',
    RECEIVE_SEARCH_METADATA = 'receiveSearchMetadata',
    RECEIVE_MEET_INTRO = 'receiveMeetIntro',
    RECEIVE_ALGO_RESULTS = 'receiveAlgoResults',
    ADD_TIMEZONE = 'addTimezone',
    ADD_SUPERDAY_TIMEZONE = 'addSuperdayTimezone',
    GET_CSRF_TOKEN = 'getCSRFToken',
    UPDATE_SELECTED_LANGUAGE = 'updateSelectedLanguage',
    ADD_API_ERROR = 'addApiError',
    RESCHEDULE = 'reschedule',
    RECEIVE_SCHEDULING_LINK_INTRO = 'receiveSchedulingLinkIntro',
    SEND_SCHEDULING_LINK_DATA = 'sendSchedulingLinkData',
    SEND_EMAIL_VALIDATION_DATA = 'sendEmailValidationData',
    RECEIVE_SUPERDAY_RESULTS = 'receiveSuperdayResults',
    SET_USER_AGENT = 'setUserAgent',
    SET_USER_ACTION = 'setUserActionTracker',
    SET_LINK_AVAILABILITY_DATA = 'linkAvailabilityData',
    SET_INTERVIEWER_AVAILABILITY_DATA = 'interviewerAvailabilityData',
    SET_DEBUG_MODE = 'setDebugMode',
    SET_FILTER_OPTIONS = 'setFilterOptions',
}

export interface AppAction<T> {
    type: AppActions;
    payload: T | undefined;
}

export const addDays = (dispatch: Dispatch<AppAction<string[]>>): ((selectedDays: string[]) => void) => {
    return (selectedDays: string[]): void => {
        dispatch({ type: AppActions.ADD_DAYS, payload: selectedDays });
    };
};

export const addTimeSlot = (dispatch: Dispatch<AppAction<string>>): ((selectedTimeSlots: string) => void) => {
    return (selectedTimeSlots: string): void => {
        dispatch({ type: AppActions.ADD_TIME_SLOT, payload: selectedTimeSlots });
    };
};

export const addTimeSlots = (dispatch: Dispatch<AppAction<string[]>>): ((selectedTimeSlots: string[]) => void) => {
    return (selectedTimeSlots: string[]): void => {
        dispatch({ type: AppActions.ADD_TIME_SLOTS, payload: selectedTimeSlots });
    };
};

export const setDebugMode = (dispatch: Dispatch<AppAction<boolean>>): ((debugMode: boolean) => void) => {
    return (debugMode: boolean): void => {
        dispatch({ type: AppActions.SET_DEBUG_MODE, payload: debugMode });
    };
};

export const setFilterOptions = (dispatch: Dispatch<AppAction<FilterOptions>>): ((filter: FilterOptions) => void) => {
    return (filter: FilterOptions): void => {
        dispatch({ type: AppActions.SET_FILTER_OPTIONS, payload: filter });
    };
};

export const startSearch = (
    dispatch: Dispatch<AppAction<Resource<SearchMetadata>>>,
): ((hash: string, debug: boolean) => Promise<SearchMetadata | undefined>) => {
    return async (interviewHash: string, debug: boolean = false): Promise<SearchMetadata | undefined> => {
        dispatch({ type: AppActions.RECEIVE_SEARCH_METADATA, payload: { isLoading: true, data: undefined } });
        try {
            const searchMetadata = await startSearchApi(interviewHash, debug);
            dispatch({
                type: AppActions.RECEIVE_SEARCH_METADATA,
                payload: {
                    isLoading: false,
                    data: searchMetadata as SearchMetadata,
                },
            });

            return searchMetadata;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error?.message === ApiErrorTypes.REQUEST_AVAILABILITY_TIME_WINDOW_EXPIRED) {
                dispatch({
                    type: AppActions.ADD_API_ERROR,
                    payload: { isLoading: false, error: error.message, data: undefined },
                });
            }
            if (error.code === 401) {
                dispatch({
                    type: AppActions.RECEIVE_SEARCH_METADATA,
                    payload: {
                        isLoading: false,
                        data: undefined,
                        error: ApiErrorTypes.UNAUTHORIZED,
                    },
                });
            }
        }
    };
};

export const getMeetIntro = (
    dispatch: Dispatch<AppAction<Resource<MeetIntro>>>,
): ((hash: string) => Promise<MeetIntro | undefined>) => {
    return async (interviewHash: string): Promise<MeetIntro | undefined> => {
        dispatch({ type: AppActions.RECEIVE_MEET_INTRO, payload: { isLoading: true, data: undefined } });
        try {
            if (window.location.pathname.startsWith('/i/')) {
                dispatch({ type: AppActions.RECEIVE_MEET_INTRO, payload: { isLoading: false, data: undefined } });
                return;
            }

            const meetIntro = await getMeetIntroApi(interviewHash);
            if (meetIntro?.hasError) {
                dispatch({
                    type: AppActions.ADD_API_ERROR,
                    payload: { isLoading: false, error: meetIntro.gtErrorName, data: meetIntro },
                });
                return;
            }
            dispatch({ type: AppActions.RECEIVE_MEET_INTRO, payload: { isLoading: false, data: meetIntro } });

            return meetIntro;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            dispatch({
                type: AppActions.ADD_API_ERROR,
                payload: { isLoading: false, error: error.gtErrorName, data: undefined },
            });
        }
    };
};

export const getCSRFToken = (dispatch: Dispatch<AppAction<{ csrfToken: string }>>): (() => Promise<void>) => {
    return async (): Promise<void> => {
        const csrfToken = await getCSRFTokenFromServerApi();
        return dispatch({ type: AppActions.GET_CSRF_TOKEN, payload: csrfToken });
    };
};

export const receiveAlgoResults = (dispatch: Dispatch<AppAction<Result[]>>): ((results: Result[]) => void) => {
    return (results: Result[]): void => {
        dispatch({ type: AppActions.RECEIVE_ALGO_RESULTS, payload: results });
    };
};

export const receiveSuperdayResults = (
    dispatch: Dispatch<AppAction<SuperdayResult[]>>,
): ((results: SuperdayResult[]) => void) => {
    return (results: SuperdayResult[]): void => {
        dispatch({ type: AppActions.RECEIVE_SUPERDAY_RESULTS, payload: results });
    };
};

export const addTimezone = (dispatch: Dispatch<AppAction<string>>): ((timezone: string) => void) => {
    return (timezone: string): void => {
        dispatch({ type: AppActions.ADD_TIMEZONE, payload: timezone });
    };
};

export const addSuperdayTimezone = (dispatch: Dispatch<AppAction<string>>): ((timezone: string) => void) => {
    return (timezone: string): void => {
        dispatch({ type: AppActions.ADD_SUPERDAY_TIMEZONE, payload: timezone });
    };
};

export const updateSelectedLanguage = (dispatch: Dispatch<AppAction<string>>): ((language: string) => void) => {
    return (language: string): void => {
        dispatch({ type: AppActions.UPDATE_SELECTED_LANGUAGE, payload: language });
    };
};

const getDurationMinutes = (startTime: string, endTime: string): number =>
    DateTime.fromISO(endTime).diff(DateTime.fromISO(startTime), ['minutes']).toObject().minutes || 0;

const resultSorter = (a: Result, b: Result): number => a.startTime.localeCompare(b.startTime);

const transformHashIntoTimeRanges = (
    selectedTimeSlots: (string | undefined)[] = [],
    availableInterviewOptions: AvailableInterviewOptions,
    results: Result[],
) => {
    const selectedTimeRanges: TimeRange[] = [];
    const selectedResults: Result[] = [];
    selectedTimeSlots.forEach((resultHash) => {
        let foundHash = false;
        availableInterviewOptions.singleDay.forEach((singleDayOptions) => {
            const singleDayOption = singleDayOptions.find((singleDayOption) => singleDayOption.hash === resultHash);
            if (singleDayOption) {
                foundHash = true;
                selectedTimeRanges.push({
                    startTime: singleDayOption.duration.startTime,
                    endTime: singleDayOption.duration.endTime,
                    durationMin: getDurationMinutes(
                        singleDayOption.duration.startTime,
                        singleDayOption.duration.endTime,
                    ),
                });
            }
        });

        if (!foundHash) {
            availableInterviewOptions.multiDay.forEach((multiDayOptions) => {
                const multiDayOption = multiDayOptions.find((multiDayOption) => multiDayOption.hash === resultHash);
                if (multiDayOption) {
                    selectedTimeRanges.push(
                        ...multiDayOption.duration.map((duration) => ({
                            startTime: duration.startTime,
                            endTime: duration.endTime,
                            durationMin: getDurationMinutes(duration.startTime, duration.endTime),
                        })),
                    );
                }
            });
        }

        const selectedResult = results.find((result) => result.hash === resultHash);
        if (selectedResult) {
            selectedResults.push(selectedResult);
        }
    });
    return { selectedResults, selectedTimeRanges };
};

export const postConfirmCandidateAvailability = (
    dispatch: Dispatch<AppAction<Resource<MeetIntro>>>,
): ((
    hash: string,
    timezone: string,
    selectedTimeSlots: (string | undefined)[],
    availableInterviewOptions: AvailableInterviewOptions,
    results: Result[],
    csrfToken: string,
    meetIntro: MeetIntro,
) => Promise<void>) => {
    return async (
        hash: string,
        timezone: string,
        selectedTimeSlots: (string | undefined)[],
        availableInterviewOptions: AvailableInterviewOptions,
        results: Result[],
        csrfToken: string,
        meetIntro: MeetIntro,
    ): Promise<void> => {
        dispatch({ type: AppActions.RECEIVE_MEET_INTRO, payload: { isLoading: true, data: meetIntro } });

        const { selectedResults, selectedTimeRanges } = transformHashIntoTimeRanges(
            selectedTimeSlots,
            availableInterviewOptions,
            results,
        );

        const earliestResult = [...selectedResults].sort(resultSorter)[0];

        try {
            const responseMeetIntro = await postConfirmCandidateAvailabilityApi(
                hash,
                {
                    guestTimeZone: timezone || '',
                    availableTimeRanges: selectedTimeRanges,
                    resultOption: earliestResult,
                },
                csrfToken,
            );
            dispatch({ type: AppActions.RECEIVE_MEET_INTRO, payload: { isLoading: false, data: responseMeetIntro } });
        } catch (err) {
            handleAPIError(dispatch, err as APIError);
        }
    };
};

export const postConfirmSuperdayAvailability = (
    dispatch: Dispatch<AppAction<Resource<MeetIntro>>>,
): ((hash: string, selectedTimeSlots: SingleDayOption[], csrfToken: string, meetIntro: MeetIntro) => Promise<void>) => {
    return async (
        hash: string,
        selectedTimeSlots: SingleDayOption[],
        csrfToken: string,
        meetIntro: MeetIntro,
    ): Promise<void> => {
        try {
            const isReschedule = meetIntro?.interview.status === InterviewStatus.RESCHEDULE;
            dispatch({ type: AppActions.RECEIVE_MEET_INTRO, payload: { isLoading: true, data: meetIntro } });
            const availabilityBlock: SuperdayAvailability[] = [];
            selectedTimeSlots.forEach((timeSlot) => {
                const { interviewBlockId, iteration } = timeSlot;
                const { startTime, endTime } = timeSlot.duration;
                if ((interviewBlockId && iteration !== undefined) || (startTime && endTime)) {
                    availabilityBlock.push({
                        interviewBlockId,
                        iteration,
                        startTime,
                        endTime,
                    });
                }
            });
            const responseMeetIntro = await confirmSuperdayAvailabilityApi(
                hash,
                availabilityBlock,
                csrfToken,
                isReschedule,
            );
            dispatch({ type: AppActions.RECEIVE_MEET_INTRO, payload: { isLoading: false, data: responseMeetIntro } });
        } catch (err) {
            handleAPIError(dispatch, err as APIError);
        }
    };
};

const handleAPIError = (dispatch: Dispatch<AppAction<Resource<MeetIntro>>>, err: APIError) => {
    dispatch({
        type: AppActions.ADD_API_ERROR,
        payload: { isLoading: false, data: undefined, error: err.message },
    });
    throw err;
};

export const reschedule = (
    dispatch: Dispatch<AppAction<{ reasonCode: string; note?: string }>>,
): ((reasonCode: string, note?: string) => void) => {
    return (reasonCode: string, note?: string) => {
        dispatch({ type: AppActions.RESCHEDULE, payload: { reasonCode, note } });
    };
};

export const postCandidateReschedule = (
    dispatch: Dispatch<AppAction<Resource<MeetIntro>>>,
): ((
    hash: string,
    csrfToken: string,
    rescheduleReason: { reasonCode: string; note?: string },
    selectedTimeSlots: (string | undefined)[],
    availableInterviewOptions: AvailableInterviewOptions,
    results: Result[],
    meetIntro: MeetIntro,
) => Promise<void>) => {
    return async (
        hash: string,
        csrfToken: string,
        rescheduleReason: { reasonCode: string; note?: string },
        selectedTimeSlots: (string | undefined)[],
        availableInterviewOptions: AvailableInterviewOptions,
        results: Result[],
        meetIntro: MeetIntro,
    ) => {
        dispatch({ type: AppActions.RECEIVE_MEET_INTRO, payload: { isLoading: true, data: meetIntro } });

        const { selectedResults, selectedTimeRanges } = transformHashIntoTimeRanges(
            selectedTimeSlots,
            availableInterviewOptions,
            results,
        );
        const earliestResult = [...selectedResults].sort(resultSorter)[0];

        try {
            const rescheduledInterview = await postCandidateRescheduleApi(
                hash,
                {
                    reasonCode: rescheduleReason.reasonCode,
                    note: rescheduleReason.note,
                    availableTimeRanges: selectedTimeRanges,
                    guestTimeZone: availableInterviewOptions.timezone,
                    resultOption: earliestResult,
                },
                csrfToken,
            );

            dispatch({
                type: AppActions.RECEIVE_MEET_INTRO,
                payload: { isLoading: false, data: rescheduledInterview },
            });
        } catch (err) {
            handleAPIError(dispatch, err as APIError);
        }
    };
};

export const getSchedulingLinkIntro = (
    dispatch: Dispatch<AppAction<Resource<SchedulingLinkIntroResponse>>>,
): ((link: string) => Promise<void>) => {
    return async (link: string) => {
        dispatch({
            type: AppActions.RECEIVE_SCHEDULING_LINK_INTRO,
            payload: { isLoading: true, data: undefined, error: undefined },
        });
        try {
            const schedulingLinkIntro = await getSchedulingLinkIntroApi(link);
            dispatch({
                type: AppActions.RECEIVE_SCHEDULING_LINK_INTRO,
                payload: { isLoading: false, data: schedulingLinkIntro },
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.gtErrorCode === ERROR.SCHEDULING_LINK_NOT_FOUND) {
                return dispatch({
                    type: AppActions.RECEIVE_SCHEDULING_LINK_INTRO,
                    payload: {
                        isLoading: false,
                        data: undefined,
                        error: String(error.gtErrorCode),
                    },
                });
            }
            dispatch({
                type: AppActions.ADD_API_ERROR,
                payload: { isLoading: false, error: error.message, data: undefined },
            });
        }
    };
};

export const sendSchedulingLinkData = (
    dispatch: Dispatch<AppAction<Resource<SchedulingLinkDataToSendResponse>>>,
): ((data: SchedulingLinkDataToSend) => Promise<void>) => {
    return async (data: SchedulingLinkDataToSend) => {
        dispatch({
            type: AppActions.SEND_SCHEDULING_LINK_DATA,
            payload: { isLoading: true, data: undefined, error: undefined },
        });
        try {
            const response = await sendSchedulingLinkDataApi(data);
            dispatch({
                type: AppActions.SEND_SCHEDULING_LINK_DATA,
                payload: { isLoading: false, data: response },
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            dispatch({
                type: AppActions.SEND_SCHEDULING_LINK_DATA,
                payload: { isLoading: false, data: undefined, error: String(error.gtErrorCode) },
            });
        }
    };
};

export const setLinkAvailabilityData = (
    dispatch: Dispatch<AppAction<Resource<LinkAvailabilityData>>>,
): ((data: LinkAvailabilityData) => Promise<void>) => {
    return async (data: LinkAvailabilityData) => {
        dispatch({
            type: AppActions.SET_LINK_AVAILABILITY_DATA,
            payload: { isLoading: true, data: undefined, error: undefined },
        });
        try {
            dispatch({
                type: AppActions.SET_LINK_AVAILABILITY_DATA,
                payload: { isLoading: false, data },
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            dispatch({
                type: AppActions.SET_LINK_AVAILABILITY_DATA,
                payload: { isLoading: false, data: undefined, error: String(error.gtErrorCode) },
            });
        }
    };
};

export const setInterviewerAvailabilityData = (
    dispatch: Dispatch<AppAction<Resource<InterviewerAvailabilityData>>>,
): ((data: InterviewerAvailabilityData) => Promise<void>) => {
    return async (data: InterviewerAvailabilityData) => {
        dispatch({
            type: AppActions.SET_INTERVIEWER_AVAILABILITY_DATA,
            payload: { isLoading: true, data: undefined, error: undefined },
        });
        try {
            dispatch({
                type: AppActions.SET_INTERVIEWER_AVAILABILITY_DATA,
                payload: { isLoading: false, data },
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            dispatch({
                type: AppActions.SET_INTERVIEWER_AVAILABILITY_DATA,
                payload: { isLoading: false, data: undefined, error: String(error.gtErrorCode) },
            });
        }
    };
};

export const setUserAgentAction = (dispatch: Dispatch<AppAction<UserAgent>>): ((data: UserAgent) => Promise<void>) => {
    return async (data: UserAgent) => {
        localStorage.setItem(LocalStorageProperty.UserAgent, data);
        dispatch({
            type: AppActions.SET_USER_AGENT,
            payload: data,
        });
    };
};

export const setUserActionTrackerAction =
    (
        dispatch: Dispatch<AppAction<SetUserActionTrackerActionInput>>,
    ): ((data: SetUserActionTrackerActionInput) => void) =>
    (data: SetUserActionTrackerActionInput) =>
        dispatch({
            type: AppActions.SET_USER_ACTION,
            payload: data,
        });
