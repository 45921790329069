let socket: WebSocket | undefined;

export const stopAvailabilitySearch = (reason?: string): void => {
    if (socket) {
        socket.close(4000, reason);
        socket = undefined;
    }
};

export const startAvailabilitySearch = (params: {
    endpoint: string;
    correlationId: string;
    sessionId: string;
    onMessage: (event: MessageEvent<string>) => void;
    onDisconnect?: (event: CloseEvent) => void;
    onConnect?: (event: Event) => void;
}): void => {
    stopAvailabilitySearch();

    const { endpoint, correlationId, sessionId, onMessage, onDisconnect, onConnect } = params;
    const queryParams = { correlationId: correlationId, sessionId: sessionId };

    socket = new WebSocket(
        `${endpoint}${`?${Object.entries(queryParams)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')}`}`,
    );

    socket.addEventListener('message', (event) => {
        onMessage(event);
    });

    socket.addEventListener('close', (event) => {
        onDisconnect?.(event);
    });

    socket.addEventListener('open', (event) => {
        onConnect?.(event);
    });
};
